import React from "react";
import { type PageProps } from "gatsby";
import { ROLES } from "schema";

import { DashboardLink } from "../components/DashboardLink";
import { UnAuthorisedPane } from "../components/Unauthorised";
import { adminNavigation } from "../const";

interface LayoutProps extends Omit<PageProps, "children"> {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const AdminLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <UnAuthorisedPane permissions={[ROLES.ADMIN]}>
      <div className="flex grow flex-col pb-6 pt-2 md:flex-row">
        {/* Left column */}
        <div className="max-w-[175px] print:hidden md:basis-3/12">
          <section className="h-full" aria-labelledby="creator-navigation">
            <h2 className="sr-only" id="section-2-title">
              Admin Navigation
            </h2>
            <nav className="h-full border-r p-6">
              {adminNavigation.map((item) => (
                <DashboardLink
                  key={item.href}
                  {...item}
                  className="flex items-center space-x-3 p-2 align-middle text-base font-medium text-foreground/70 hover:text-accent-foreground"
                  activeClassName="text-accent-foreground/70"
                />
              ))}
            </nav>
          </section>
        </div>

        {/* Right column */}
        <div className="flex-row md:basis-9/12 lg:grow">
          <section aria-labelledby="main-section" className="h-full">
            <h2 className="sr-only print:hidden" id="section-1-title">
              Main Section
            </h2>
            {children}
          </section>
        </div>
      </div>
    </UnAuthorisedPane>
  );
};
