import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "tailwind/utils";

const iconVariants = cva("", {
  variants: {
    variant: {
      default: "material-symbols-outlined",
      rounded: "material-symbols-rounded",
      sharp: "material-symbols-sharp",
    },
    size: {
      default: "text-lg",
      xxs: "text-xs",
      xs: "text-sm",
      sm: "text-base",
      lg: "text-xl",
      xl: "text-2xl",
      "2xl": "text-3xl",
      "3xl": "text-4xl",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface IconProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof iconVariants> {
  icon: string;
}

const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  ({ className, variant, size, icon, ...props }, ref) => {
    return (
      <span
        className={cn(iconVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {icon}
      </span>
    );
  },
);
Icon.displayName = "Icon";

export { Icon, iconVariants };
