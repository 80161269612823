module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_6u7fhjcafy7pzopc6bm5hn5mre/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3N6H5QLSH0"],"gtagConfig":{"cookie_expires":0,"anonymize_ip":false},"pluginConfig":{"head":false,"origin":"https://app.willcraftnow.com","delayOnRouteUpdate":0,"respectDNT":false,"exclude":[]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__znh7ztnotopnv2jitgz6zdcky4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
