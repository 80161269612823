import { logError } from "@/utils";
import React from "react";
import { Button } from "ui/components/button";

export class ErrorPaneError extends Error {
  statusCode: number;
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

export const ErrorPane: React.FC<{
  error: any;
}> = ({ error }) => {
  const errorCode =
    error?.data?.statusCode || error?.statusCode || "Unknown Error";
  const message =
    error?.data?.message || error?.message || "Unknown Error Message";


  React.useEffect(() => {
    logError("Generic Catch Error", "ErrorPane", error);
  }, [])

  return (
    <div className="grid h-full w-full grow place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-accent-foreground">
          {errorCode}
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-5xl">
          Oops.
        </h1>
        <p className="mt-2 text-base leading-7 text-muted-foreground">
          Something went wrong on our side. Please try again later.
        </p>
        <div className="mt-3 flex items-center justify-center gap-x-6">
          <Button asChild variant="ghost">
            <a
              href={`mailto:enquiries@willcraftnow.com?subject=Error ${errorCode} - ${JSON.stringify(
                message,
              )}&body=${JSON.stringify(message)}!`}
            >
              Contact Us Directly &nbsp; <span aria-hidden="true">&rarr;</span>
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};
