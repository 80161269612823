import { MetaUserResource } from "../../user-resource/entity";
import { z } from "../../xzod";

export enum ALLOCATION_MODE_ENUM {
  NONE = "No Substitute Allocations.",
  EQUALLY = "Distribute Equally to Selected People.",
  EQUALLY_AMONGST_CHILDREN = "Distribute Equally to Biological Children.",
  CUSTOM_PERCENT = "Distribute By Percentage to Selected People.",
  CUSTOM_AMOUNT = "Distribute By Amount to Selected People.",
}

/**
 * Literals
 */

export const AllocationModeNone = z.literal(ALLOCATION_MODE_ENUM.NONE);
export const AllocationModeEqually = z.literal(ALLOCATION_MODE_ENUM.EQUALLY);
export const AllocationModeChildren = z.literal(
  ALLOCATION_MODE_ENUM.EQUALLY_AMONGST_CHILDREN,
);
export const AllocationModeCustomPercent = z.literal(
  ALLOCATION_MODE_ENUM.CUSTOM_PERCENT,
);
export const AllocationModeCustomAmount = z.literal(
  ALLOCATION_MODE_ENUM.CUSTOM_AMOUNT,
);

/**
 * FieldSchemas
 */

const PersonIdFieldSchema = z
  .string()
  .min(1, "Please Appoint A Person or Charity.")
  .meta({
    metaKey: "entity.Person",
    resourceSchema: MetaUserResource,
    resourceAdd: {
      key: "entity",
      description: "Add a Person or Charity",
    },
  });

export const SubstituteAllocationModeFieldSchema = z
  .nativeEnum(ALLOCATION_MODE_ENUM)
  .default(ALLOCATION_MODE_ENUM.NONE);
export const PercentageAllocationFieldSchema = z.coerce
  .number()
  .max(100)
  .min(0)
  .multipleOf(0.01);
export const AmountAllocationFieldSchema = z.coerce
  .number()
  .min(0)
  .multipleOf(0.01);

/**
 * Base Schemas
 */

export const BaseAllocationsSchema = z.object({
  beneficiary: PersonIdFieldSchema,
});

export const BasePercentageAllocationsSchema = BaseAllocationsSchema.extend({
  allocation: PercentageAllocationFieldSchema,
  substituteAllocationMode: SubstituteAllocationModeFieldSchema,
});

export const BaseAmountAllocationsSchema = BaseAllocationsSchema.extend({
  allocation: AmountAllocationFieldSchema,
  substituteAllocationMode: SubstituteAllocationModeFieldSchema,
});

/**
 * Base Types
 */

type TBaseAllocation = Omit<
  z.infer<typeof BaseAllocationsSchema>,
  "substituteAllocationMode"
>;

type TBasePercentageAllocation = Omit<
  z.infer<typeof BasePercentageAllocationsSchema>,
  "substituteAllocationMode"
>;

type TBaseAmountAllocation = Omit<
  z.infer<typeof BaseAmountAllocationsSchema>,
  "substituteAllocationMode"
>;

/**
 * Amount Only Allocation Schemas
 */

type TAmountOnlySimpleAllocation =
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeNone>;
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeEqually>;
      substituteAllocations: TAmountOnlySimpleAllocation[];
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeChildren>;
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomAmount>;
      substituteAllocations: TAmountOnlyAllocation[];
    });

export type TAmountOnlyAllocation =
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeNone>;
    })
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeEqually>;
      substituteAllocations: TAmountOnlySimpleAllocation[];
    })
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeChildren>;
    })
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomAmount>;
      substituteAllocations: TAmountOnlyAllocation[];
    });

export const AmountOnlySimpleAllocationsSchema: z.ZodType<TAmountOnlySimpleAllocation> =
  z.discriminatedUnion("substituteAllocationMode", [
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeNone,
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeEqually,
      substituteAllocations: z.lazy(() =>
        AmountOnlySimpleAllocationsSchema.array(),
      ),
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeChildren,
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomAmount,
      substituteAllocations: z.lazy(() => AmountOnlyAllocationsSchema.array()),
    }),
  ]);

export const AmountOnlyAllocationsSchema: z.ZodType<TAmountOnlyAllocation> =
  z.discriminatedUnion("substituteAllocationMode", [
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeNone,
    }),
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeEqually,
      substituteAllocations: z.lazy(() =>
        AmountOnlySimpleAllocationsSchema.array(),
      ),
    }),
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeChildren,
    }),
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomAmount,
      substituteAllocations: z.lazy(() => AmountOnlyAllocationsSchema.array()),
    }),
  ]);

/**
 * Percentage Only Allocation Schemas
 */

type TPercentageOnlySimpleAllocation =
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeNone>;
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeEqually>;
      substituteAllocations: TPercentageOnlySimpleAllocation[];
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeChildren>;
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomPercent>;
      substituteAllocations: TPercentageOnlyAllocation[];
    });

export type TPercentageOnlyAllocation =
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeNone>;
    })
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeEqually>;
      substituteAllocations: TPercentageOnlySimpleAllocation[];
    })
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeChildren>;
    })
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomPercent>;
      substituteAllocations: TPercentageOnlyAllocation[];
    });

export const PercentageOnlySimpleAllocationsSchema: z.ZodType<TPercentageOnlySimpleAllocation> =
  z.discriminatedUnion("substituteAllocationMode", [
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeNone,
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeEqually,
      substituteAllocations: z.lazy(() =>
        PercentageOnlySimpleAllocationsSchema.array(),
      ),
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeChildren,
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomPercent,
      substituteAllocations: z.lazy(() =>
        PercentageOnlyAllocationsSchema.array(),
      ),
    }),
  ]);

export const PercentageOnlyAllocationsSchema: z.ZodType<TPercentageOnlyAllocation> =
  z.discriminatedUnion("substituteAllocationMode", [
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeNone,
    }),
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeEqually,
      substituteAllocations: z.lazy(() =>
        PercentageOnlySimpleAllocationsSchema.array(),
      ),
    }),
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeChildren,
    }),
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomPercent,
      substituteAllocations: z.lazy(() =>
        PercentageOnlyAllocationsSchema.array(),
      ),
    }),
  ]);

/**
 * Dual Allocation Schemas
 */

type TDualSimpleAllocation =
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeNone>;
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeEqually>;
      substituteAllocations: TDualSimpleAllocation[];
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeChildren>;
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomPercent>;
      substituteAllocations: TDualPercentageAllocation[];
    })
  | (TBaseAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomAmount>;
      substituteAllocations: TDualAmountAllocation[];
    });

type TDualPercentageAllocation =
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeNone>;
    })
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeEqually>;
      substituteAllocations: TDualSimpleAllocation[];
    })
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeChildren>;
    })
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomPercent>;
      substituteAllocations: TDualPercentageAllocation[];
    })
  | (TBasePercentageAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomAmount>;
      substituteAllocations: TDualAmountAllocation[];
    });

type TDualAmountAllocation =
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeNone>;
    })
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeEqually>;
      substituteAllocations: TDualSimpleAllocation[];
    })
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeChildren>;
    })
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomPercent>;
      substituteAllocations: TDualPercentageAllocation[];
    })
  | (TBaseAmountAllocation & {
      substituteAllocationMode: z.infer<typeof AllocationModeCustomAmount>;
      substituteAllocations: TDualAmountAllocation[];
    });

export const DualSimpleAllocationsSchema: z.ZodType<TDualSimpleAllocation> =
  z.discriminatedUnion("substituteAllocationMode", [
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeNone,
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeEqually,
      substituteAllocations: z.lazy(() => DualSimpleAllocationsSchema.array()),
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeChildren,
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomPercent,
      substituteAllocations: z.lazy(() =>
        DualPercentageAllocationsSchema.array(),
      ),
    }),
    BaseAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomAmount,
      substituteAllocations: z.lazy(() => DualAmountAllocationsSchema.array()),
    }),
  ]);

export const DualPercentageAllocationsSchema: z.ZodType<TDualPercentageAllocation> =
  z.discriminatedUnion("substituteAllocationMode", [
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeNone,
    }),
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeEqually,
      substituteAllocations: z.lazy(() => DualSimpleAllocationsSchema.array()),
    }),
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeChildren,
    }),
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomPercent,
      substituteAllocations: z.lazy(() =>
        DualPercentageAllocationsSchema.array(),
      ),
    }),
    BasePercentageAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomAmount,
      substituteAllocations: z.lazy(() => DualAmountAllocationsSchema.array()),
    }),
  ]);

export const DualAmountAllocationsSchema: z.ZodType<TDualPercentageAllocation> =
  z.discriminatedUnion("substituteAllocationMode", [
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeNone,
    }),
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeEqually,
      substituteAllocations: z.lazy(() => DualSimpleAllocationsSchema.array()),
    }),
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeChildren,
    }),
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomPercent,
      substituteAllocations: z.lazy(() =>
        DualPercentageAllocationsSchema.array(),
      ),
    }),
    BaseAmountAllocationsSchema.extend({
      substituteAllocationMode: AllocationModeCustomAmount,
      substituteAllocations: z.lazy(() => DualAmountAllocationsSchema.array()),
    }),
  ]);
