import React from "react";
import { cn } from "tailwind/utils";

export const GuardianLawLogo: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <>
      <span
        className={cn(
          "logo logo-main text-xl font-semibold tracking-[0.16em] text-primary",
          className,
        )}
      >
        Guardian
      </span>
      <span
        className={cn(
          "logo logo-main text-xl font-semibold tracking-[0.16em] text-accent-foreground",
          className,
        )}
      >
        Law
      </span>
    </>
  );
};

export const WillCraftLogo: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className="inline-block flex flex-col justify-end">
      <p style={{ fontFamily: "Montserrat" }}>
        <span
          className={cn(
            "logo logo-main text-2xl font-semibold text-primary",
            className,
          )}
        >
          WILL
        </span>
        <span
          className={cn(
            "logo logo-main text-2xl font-semibold text-primary decoration-accent-foreground",
            className,
          )}
        >
          CRAFT
        </span>
      </p>
      <p
        className={cn(
          "logo font-base logo-subtitle pr-2.5 text-right text-xs text-accent-foreground",
          className,
        )}
      >
        By Guardian Law
      </p>
    </div>
  );
};
