import { z } from "./xzod";

/**
 * Authentication Schemas
 */

export const AUTH_ERROR_MESSAGES = {
  SERVER: "Something went wrong, please contact us directly.",
  GOOGLE: "Please login with Google.",
  MAGIC_LINK: "Please login with Email.",
  NOT_FOUND: "Account not found. Please proceed to register.",
  EXISTS: "Account already exists. Please proceed to login.",
  INVALID_CREDS: "Invalid credentials.",
  INVALID_CODE: "Invalid code. Please request a new one.",
  EXPIRED_CODE: "Code has expired. Please request a new one.",
};

export enum EMAIL_MODES {
  DOES_NOT_EXIST = "does-not-exist",
  EXISTS_WITHOUT_PASSWORD = "exists-without-password",
  EXISTS_WITH_PASSWORD = "exists-with-password",
}

export const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8).max(20),
});

export const RegisterSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8).max(20),
    passwordAgain: z.string().min(8).max(20),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.passwordAgain) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords do not match.",
        path: ["passwordAgain"],
      });
    }
    return true;
  });

export const ChangePasswordSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8).max(20),
  passwordAgain: z.string().min(8).max(20),
});

export const LostPasswordSchema = z.object({
  email: z.string().email(),
});

export const GoogleJsonResponseSchema = z.object({
  sub: z.string(),
  name: z.string(),
  given_name: z.string(),
  family_name: z.string(),
  picture: z.string().url(),
  email: z.string().email(),
  email_verified: z.boolean(),
  locale: z.string(),
  // hd: "hello.ai",
  hd: z.string(),
});
// note: id and sub is the same
export const GoogleResponseSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  name: z.object({
    familyName: z.string(),
    givenName: z.string(),
  }),
  emails: z.array(
    z.object({
      value: z.string().email(),
      verified: z.boolean(),
    }),
  ),
  photos: z.array(
    z.object({
      value: z.string().url(),
    }),
  ),
  provider: z.literal("google"),
  _raw: z.string(),
  _json: GoogleJsonResponseSchema,
});
