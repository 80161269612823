import * as React from "react";
import { useId } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon } from "@radix-ui/react-icons";
import { capitalCase, sentenceCase } from "change-case-all";
import { format } from "date-fns";
import merge from "deepmerge";
import {
  FieldValues,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { cn } from "tailwind/utils";

import { Alert, AlertDescription, AlertTitle } from "./alert";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Checkbox } from "./checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./form";
import { ZSelect, ZSelectOption } from "./form-z-select";
import { Input } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import { useToast } from "./use-toast";
import { z } from "./zod";

export const FormResolver = zodResolver;

export const setFormApiErrors = (form: UseFormReturn<any>, issues: any) => {
  if (!Array.isArray(issues)) {
    console.error("Issues must be an array");
    return;
  }
  const allParsedErrors = issues.reduce<{
    fieldErrors: Record<string, string>;
    formErrors: string | undefined;
  }>(
    (acc, issue) => {
      const { path, message } = issue;
      const identifier = path.join(".");
      if (!identifier && !acc.formErrors) {
        acc.formErrors = message;
      }
      if (!(identifier in acc.fieldErrors)) {
        acc.fieldErrors[identifier] = message;
      }

      return acc;
    },
    { fieldErrors: {}, formErrors: undefined },
  );

  for (const key in allParsedErrors.fieldErrors) {
    form.setError(key, {
      type: "server",
      message: allParsedErrors.fieldErrors[key],
    });
  }

  if (allParsedErrors.formErrors) {
    form.setError("root.server", {
      type: "server",
      message: allParsedErrors.formErrors,
    });
  }

  return allParsedErrors;
};

type CallOutProps = {
  title: string;
  description: string;
  icon: string;
};

export function CallOut({
  callouts,
}: {
  callouts: CallOutProps | CallOutProps[] | undefined;
}) {
  if (!callouts) return null;

  if (Array.isArray(callouts)) {
    return (
      <>
        {callouts.map((c) => (
          <CallOut key={Math.random()} callouts={c} />
        ))}
      </>
    );
  }

  return (
    <Alert>
      <CheckIcon className="h-4 w-4" />
      <AlertTitle>{callouts.title || "Title"}</AlertTitle>
      <AlertDescription>
        {callouts.description || "Description"}
      </AlertDescription>
    </Alert>
  );
}

export interface IZFormSchema {
  displayName: string;
  schema: z.ZodFirstPartySchemaTypes;
}

export interface IZFormProps {
  schema: z.ZodFirstPartySchemaTypes;
  defaultValues?: Partial<z.infer<IZFormProps["schema"]>>;
  onSubmit: (values: z.infer<IZFormProps["schema"]>) => Promise<void>;
}

export interface IZFormFieldProps {
  schema: z.ZodFirstPartySchemaTypes;
  fieldName: string;
  identifierTrace: string[];
  defaultValueSchema?: z.ZodDefault<any>;
  effect?: z.Effect<any>[];
}

const ZFormContext = React.createContext<{
  plugins: ZodFormInitialisedPlugin[];
}>({
  plugins: [],
});

function hasPunctuation(inputString: string) {
  // Define a regular expression pattern to match punctuation characters
  const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;

  // Use the test() method to check if the inputString contains any punctuation
  return punctuationRegex.test(inputString);
}

export const ZFormLabel = (
  props: IZFormFieldProps & { className?: string },
) => {
  const label =
    getSchema(props.schema)._def.displayName || props.fieldName || "Unnamed";
  const labelIsSentence = hasPunctuation(label);
  return (
    <FormLabel className={props.className}>
      {labelIsSentence ? label : capitalCase(label)}
    </FormLabel>
  );
};

export const ZFormDescription = (props: IZFormFieldProps) => {
  return (
    <FormDescription className="truncate">
      {sentenceCase(props.schema._def.description || "")}
    </FormDescription>
  );
};

export const FormFieldComponent = (props: IZFormFieldProps) => {
  const methods = useFormContext();
  const formDisabled =
    methods.formState.isSubmitting || methods.formState.isLoading;
  const identifierTrace = props.identifierTrace.filter(Boolean);
  const identifier = React.useMemo(() => {
    return [...identifierTrace, props.fieldName].join(".");
  }, [identifierTrace, props.fieldName]);

  const { plugins } = React.useContext(ZFormContext);

  const parseResult = props.schema._def.showOn
    ? props.schema._def.showOn.safeParse(
        methods.watch(props.schema._def.showOn._def.path),
      )
    : undefined;

  React.useEffect(() => {
    if (parseResult && !parseResult.success) {
      methods.unregister(identifier);
    }
  }, [parseResult?.success]);

  if (parseResult && !parseResult.success) {
    return null;
  }

  const className = props.schema._def.hidden ? "hidden" : "";

  const activePlugin = plugins.find((p) => p(props));

  if (activePlugin) {
    const Component = activePlugin(props);
    if (Component) return <Component {...props} />;
    return "Error: Plugin returned undefined";
  }

  switch (props.schema._def.typeName) {
    case "ZodDiscriminatedUnion": {
      const discriminator = props.schema._def.discriminator;
      const discriminatorIdentifier = [...identifierTrace, discriminator].join(
        ".",
      );

      const discriminatorValue = methods.watch(discriminatorIdentifier);
      const keys = Array.from(props.schema._def.optionsMap.keys());
      const isBool = keys.every((e) => typeof e === "boolean");
      const currSchema =
        props.schema._def.optionsMap.get(discriminatorValue) ||
        props.schema._def.options[0];
      const { typeName, value, ...currFieldMeta } =
        currSchema._def.shape()[discriminator]._def;

      return (
        <>
          <FormFieldComponent
            fieldName={props.fieldName}
            schema={currSchema.extend({
              [discriminator]: isBool
                ? z.boolean().meta(currFieldMeta).default(value)
                : z.nativeEnum(keys).default(value).meta(currFieldMeta),
            })}
            identifierTrace={identifierTrace}
          />
        </>
      );
    }
    case "ZodObject": {
      const shape = props.schema._def.shape();
      return (
        <>
          <CallOut callouts={props.schema._def.callouts} />
          {Object.keys(shape).map((k) => (
            <FormFieldComponent
              schema={shape[k]}
              key={`${identifier}.${k}`}
              fieldName={k}
              identifierTrace={identifierTrace}
            />
          ))}
        </>
      );
    }
    case "ZodNumber": {
      const getCheckValue = (kind: "min" | "max" | "multipleOf") => {
        // @ts-ignore
        const hasStep = props.schema._def.checks.find((c) => c.kind === kind);
        return hasStep?.value;
      };
      // @ts-ignore
      const step = getCheckValue("multipleOf");
      const min = getCheckValue("min");
      const max = getCheckValue("max");
      const defaultValue = props.defaultValueSchema?._def.defaultValue();
      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          name={identifier}
          defaultValue={defaultValue}
          render={({ field }) => (
            <FormItem id={identifier} className={cn(className)}>
              <ZFormLabel {...props} />
              <CallOut callouts={props.schema._def.callouts} />
              <FormControl>
                <Input
                  className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border focus-visible:ring-offset-1"
                  placeholder={props.schema._def.placeholder}
                  {...field}
                  disabled={formDisabled || props.schema._def.disabled}
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsed = Number.isInteger(step)
                      ? parseInt(value)
                      : parseFloat(value);
                    Object.assign(e.target, {
                      value: parsed,
                    });
                    if (min !== undefined && parsed < min) {
                      Object.assign(e.target, {
                        value: min,
                      });
                    }
                    if (max !== undefined && parsed > max) {
                      Object.assign(e.target, {
                        value: max,
                      });
                    }
                    field.onChange(e);
                  }}
                  type="number"
                  min={min}
                  max={max}
                  step={step}
                />
              </FormControl>
              <ZFormDescription {...props} />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    }
    case "ZodDate": {
      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          name={identifier}
          defaultValue={props.defaultValueSchema?._def.defaultValue()}
          render={({ field }) => (
            <FormItem
              id={identifier}
              className={cn(className, "flex flex-col")}
            >
              <ZFormLabel {...props} />
              <CallOut callouts={props.schema._def.callouts} />
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-[240px] pl-3 text-left font-normal",
                        !field.value && "text-muted-foreground",
                      )}
                      disabled={formDisabled || props.schema._def.disabled}
                    >
                      {field.value ? (
                        format(field.value, "PPP")
                      ) : (
                        <span>Pick a date</span>
                      )}
                      {/* <CalendarIcon className="ml-auto h-4 w-4 opacity-50" /> */}
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    // disabled={(date) =>
                    //   date > new Date() || date < new Date("1900-01-01")
                    // }
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <ZFormDescription {...props} />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    }
    case "ZodString": {
      const choiceMap = props.schema._def.choiceMap;

      const transforms = props.effect?.filter((e) => e.type === "transform") as
        | z.TransformEffect<any>[]
        | undefined;

      if (choiceMap) {
        const defaultValue =
          props.defaultValueSchema?._def.defaultValue() ||
          Object.keys(choiceMap)[0];

        return (
          <FormField
            control={methods.control}
            shouldUnregister={true}
            name={identifier}
            defaultValue={defaultValue}
            render={({ field }) => {
              return (
                <FormItem id={identifier} className={cn(className)}>
                  <ZFormLabel {...props} />
                  <CallOut callouts={props.schema._def.callouts} />
                  <FormControl>
                    <ZSelect
                      onChange={field.onChange}
                      value={field.value}
                      getDisplayValue={() =>
                        field.value ? choiceMap[field.value] || "" : ""
                      }
                      multiple={false}
                      placeholder={props.schema._def.placeholder}
                      className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border focus-visible:ring-offset-1"
                    >
                      {choiceMap &&
                        Object.entries(choiceMap).map(([k, v]) => (
                          <ZSelectOption key={k} value={k} display={v} />
                        ))}
                    </ZSelect>
                  </FormControl>
                  <ZFormDescription {...props} />
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        );
      }

      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          name={identifier}
          defaultValue={props.defaultValueSchema?._def.defaultValue() || ""}
          render={({ field }) => (
            <FormItem id={identifier} className={cn(className)}>
              <ZFormLabel {...props} />
              <CallOut callouts={props.schema._def.callouts} />
              <FormControl>
                <Input
                  className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border focus-visible:ring-offset-1"
                  placeholder={props.schema._def.placeholder}
                  disabled={formDisabled || props.schema._def.disabled}
                  {...field}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (transforms) {
                      for (const transform of transforms) {
                        // @ts-ignore missing refinement ctx
                        value = transform.transform(value);
                      }
                    }
                    e.target.value = value;
                    field.onChange(e);
                  }}
                  data-testid={identifier}
                />
              </FormControl>
              <ZFormDescription {...props} />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    }
    case "ZodBoolean": {
      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          name={identifier}
          defaultValue={props.defaultValueSchema?._def.defaultValue()}
          render={({ field }) => (
            <FormItem id={identifier} className={cn(className)}>
              <CallOut callouts={props.schema._def.callouts} />
              <div className="flex items-center space-x-2">
                <ZFormLabel {...props} />
                <FormControl>
                  <Checkbox
                    className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border focus-visible:ring-offset-1"
                    onCheckedChange={field.onChange}
                    checked={field.value}
                    disabled={formDisabled || props.schema._def.disabled}
                  />
                </FormControl>
              </div>
              <ZFormDescription {...props} />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    }
    case "ZodEnum": {
      const values = props.schema._def.values as string[];
      const choiceMap = props.schema._def.choiceMap || {};

      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          defaultValue={props.defaultValueSchema?._def.defaultValue()}
          name={identifier}
          render={({ field }) => (
            <FormItem id={identifier} className={cn(className)}>
              <ZFormLabel {...props} />
              <CallOut callouts={props.schema._def.callouts} />
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger
                    {...field}
                    disabled={formDisabled || props.schema._def.disabled}
                    className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border focus-visible:ring-offset-1"
                  >
                    <SelectValue placeholder={props.schema._def.placeholder} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {/* {values.map((v) => (
                    <SelectItem key={v} value={v}>
                      {choiceMap[v] || v}
                    </SelectItem>
                  ))} */}
                </SelectContent>
              </Select>
              <ZFormDescription {...props} />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    }
    case "ZodNativeEnum": {
      const { values: choiceMap } = props.schema._def;
      const values = Object.keys(choiceMap);

      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          name={identifier}
          defaultValue={props.defaultValueSchema?._def.defaultValue()}
          render={({ field }) => {
            return (
              <FormItem id={identifier} className={cn(className)}>
                <ZFormLabel {...props} />
                <CallOut callouts={props.schema._def.callouts} />
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      {...field}
                      disabled={formDisabled || props.schema._def.disabled}
                      className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border focus-visible:ring-offset-1"
                    >
                      <SelectValue
                        placeholder={props.schema._def.placeholder}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {values.map((v) => {
                      if (!choiceMap[v]) {
                        return null;
                      }
                      return (
                        <SelectItem key={v} value={choiceMap[v]}>
                          {choiceMap[v]}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <ZFormDescription {...props} />
                <FormMessage />
              </FormItem>
            );
          }}
        />
      );
    }
    case "ZodLiteral": {
      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          name={identifier}
          defaultValue={props.schema._def.value}
          render={({ field }) => (
            <FormItem id={identifier} className={cn(className)}>
              <ZFormLabel {...props} />
              <CallOut callouts={props.schema._def.callouts} />
              <FormControl>
                <Input
                  placeholder={props.schema._def.placeholder}
                  {...field}
                  disabled
                />
              </FormControl>
              <ZFormDescription {...props} />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    }
    case "ZodArray": {
      const { fields, append, prepend, remove, swap, move, insert } =
        useFieldArray({
          control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
          name: identifier,
        });

      const itemSchema = props.schema._def.type;

      return (
        <>
          <ZFormLabel {...props} />
          <CallOut callouts={props.schema._def.callouts} />
          <div className={cn("space-y-2", "border-l-2 px-2")}>
            {fields.map((field, index) => {
              return (
                <div key={field.id}>
                  <FormFieldComponent
                    fieldName={``}
                    schema={itemSchema}
                    identifierTrace={[
                      ...identifierTrace,
                      props.fieldName,
                      `${index}`,
                    ]}
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      remove(index);
                    }}
                    type="button"
                    disabled={formDisabled || props.schema._def.disabled}
                  >
                    Delete
                  </Button>
                </div>
              );
            })}
            <Button
              size="sm"
              onClick={() => {
                append({ ...getSchemaDefaults(itemSchema) });
              }}
              disabled={formDisabled || props.schema._def.disabled}
              type="button"
            >
              Add
            </Button>
          </div>
          <ZFormDescription {...props} />
          <FormMessage />
        </>
      );
    }
    case "ZodDefault": {
      return (
        <FormFieldComponent
          {...props}
          fieldName={props.fieldName}
          schema={props.schema._def.innerType.meta({
            placeholder: props.schema._def.placeholder,
            hidden: props.schema._def.hidden,
            disabled: props.schema._def.disabled,
            choiceMap: props.schema._def.choiceMap,
            stringSuffix: props.schema._def.stringSuffix,
            iconSuffix: props.schema._def.iconSuffix,
            iconPrefix: props.schema._def.iconPrefix,
            stringPrefix: props.schema._def.stringPrefix,
          })}
          identifierTrace={identifierTrace}
          defaultValueSchema={props.schema as z.ZodDefault<any>}
        />
      );
    }
    case "ZodNullable": {
      return (
        <FormFieldComponent
          {...props}
          fieldName={props.fieldName}
          schema={props.schema._def.innerType}
          identifierTrace={identifierTrace}
        />
      );
    }
    case "ZodLazy": {
      return (
        <FormFieldComponent
          {...props}
          fieldName={props.fieldName}
          schema={props.schema._def.getter()}
          identifierTrace={identifierTrace}
        />
      );
    }
    case "ZodEffects": {
      return (
        <FormFieldComponent
          {...props}
          fieldName={props.fieldName}
          schema={props.schema._def.schema}
          identifierTrace={identifierTrace}
          effect={
            props.effect
              ? [...props.effect, props.schema._def.effect]
              : [props.schema._def.effect]
          }
        />
      );
    }
    case "ZodOptional": {
      return (
        <FormFieldComponent
          {...props}
          fieldName={props.fieldName}
          schema={
            props.schema instanceof z.ZodOptional
              ? props.schema.unwrap()
              : undefined
          }
          identifierTrace={identifierTrace}
        />
      );
    }
  }

  return (
    <>{`Unidentified Field - [${props.fieldName}] [${props.schema._def.typeName}]`}</>
  );
};

export function getSchemaDefaults<Schema extends z.ZodFirstPartySchemaTypes>(
  schema: Schema,
): Partial<z.infer<Schema>> {
  if (schema instanceof z.ZodDiscriminatedUnion) {
    // return merge.all(schema._def.options.map((o) => getSchemaDefaults(o)));
    return getSchemaDefaults(schema._def.options[0]);
  }

  if (schema instanceof z.ZodArray) return [];

  if (schema instanceof z.ZodEffects) {
    return getSchemaDefaults(schema._def.schema);
  }
  if (schema instanceof z.ZodObject)
    return Object.fromEntries(
      Object.keys(schema._def.shape()).map((key) => {
        let value = getSchema(schema._def.shape()[key]);

        if (value instanceof z.ZodOptional) {
          value = value.unwrap();
        }

        switch (value._def.typeName) {
          case "ZodEnum":
            return [key, value._def.values[0]];
          case "ZodLiteral":
            return [key, value._def.value];
          case "ZodDefault":
            return [key, value._def.defaultValue()];
          case "ZodBoolean":
            return [key, false];
          case "ZodNumber":
            return [key, 0];
          case "ZodString": {
            if (value._def.choiceMap)
              return [key, Object.keys(value._def.choiceMap)[0] || ""];
            return [key, ""];
          }
          case "ZodArray":
            return [key, []];
          case "ZodObject":
            return getSchemaDefaults(value);
          case "ZodEffects":
            return getSchemaDefaults(value._def.schema);

          default:
            return [key, ""];
        }
      }) as [string, any][],
    ) as z.infer<Schema>;

  return {};
}

type UseZodForm<TInput extends FieldValues> = UseFormReturn<TInput> & {
  /**
   * A unique ID for this form.
   */
  id: string;
  schema: z.ZodFirstPartySchemaTypes;
};

export function useZodForm<TSchema extends z.ZodType>(
  props: Omit<UseFormProps<TSchema["_input"]>, "resolver"> & {
    schema: z.ZodFirstPartySchemaTypes;
    defaultValues: Partial<z.infer<TSchema>>;
    id: string;
  },
) {
  const { id, ...passThrough } = props;
  const formId = id || useId();
  const defaultValues = React.useMemo(
    () => merge(getSchemaDefaults(props.schema), props.defaultValues),
    [props.schema, props.defaultValues],
  );

  const form = useForm<z.ZodFirstPartySchemaTypes>({
    ...passThrough,
    defaultValues,
    resolver: zodResolver(props.schema, undefined, {
      // This makes it so we can use `.transform()`s on the schema without same transform getting applied again when it reaches the server
      raw: true,
    }),
  }) as UseZodForm<TSchema["_input"]>;

  if (props.schema instanceof z.ZodArray)
    console.error("Cannot initialise form with ZodArray");

  form.id = formId;
  form.schema = props.schema;

  return form;
}

export type AnyZodForm = UseZodForm<any>;

export type ZodFormInitialisedPlugin = (
  arg: IZFormFieldProps,
) => React.FunctionComponent<IZFormFieldProps> | undefined;

export type ZodFormPlugin = (arg?: any) => ZodFormInitialisedPlugin;

export function ZForm<TInput extends FieldValues>(
  props: Omit<React.ComponentProps<"form">, "onSubmit" | "id"> & {
    handleSubmit: SubmitHandler<TInput>;
    form: UseZodForm<TInput>;
    plugins: ZodFormInitialisedPlugin[] | undefined;
  },
) {
  const {
    handleSubmit,
    form,
    className,
    plugins = [],
    ...passThrough
  }: typeof props = props;
  const { toast } = useToast();

  const rootErrors = form.formState.errors.root;

  React.useEffect(() => {
    if (rootErrors?.server) {
      toast({
        title: "Uhoh!",
        description: rootErrors.server.message,
      });
    }
  }, [rootErrors]);

  return (
    <Form {...form}>
      <ZFormContext.Provider value={{ plugins }}>
        <form
          {...passThrough}
          id={form.id}
          className={cn("space-y-3", className)}
          onSubmit={(event) => {
            form.handleSubmit(async (values) => {
              try {
                await handleSubmit(values, event);
              } catch (cause) {
                form.setError("root.server", {
                  message: (cause as Error)?.message ?? "Unknown error",
                  type: "server",
                });
              }
            })(event);
          }}
          onError={() => {
            toast({
              title: "Uhoh!",
              description:
                "Something Went Wrong. Please contact customer support.",
            });
          }}
        >
          <FormFieldComponent
            schema={form.schema}
            fieldName=""
            identifierTrace={[]}
          />
        </form>
      </ZFormContext.Provider>
    </Form>
  );
}

export const getSchema = (
  schema: z.ZodFirstPartySchemaTypes,
): z.ZodFirstPartySchemaTypes => {
  if (schema instanceof z.ZodOptional) {
    const unwrapped = schema.unwrap();
    return unwrapped instanceof z.ZodOptional
      ? getSchema(unwrapped)
      : unwrapped;
  }
  return schema;
};

export function SubmitButton(
  props: Omit<React.ComponentProps<typeof Button>, "type" | "form"> &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type" | "form"> & {
      /**
       * Optionally specify a form to submit instead of the closest form context.
       */
      form?: AnyZodForm;
      submittingText?: string;
    },
) {
  const context = useFormContext();
  const {
    form: propsForm,
    className,
    submittingText = "Submitting...",
    ...passThrough
  } = props;
  const form = propsForm ?? context;
  if (!form) {
    throw new Error(
      "SubmitButton must be used within a Form or have a form prop",
    );
  }
  const { formState } = form;

  return (
    <Button
      {...passThrough}
      className={cn(
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border focus-visible:ring-offset-1",
        className,
      )}
      form={props.form?.id}
      type="submit"
      disabled={formState.isSubmitting}
    >
      {formState.isSubmitting ? submittingText : props.children}
    </Button>
  );
}
