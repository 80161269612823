export type Block = {
  type: string;
  children: (Text | Block)[];
  [arg: string]: any;
};

export type Text = {
  text: string;
  bold?: boolean;
};

function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export const makeBlock = (
  text: Block | Text | (Block | Text | undefined)[],
  opts?: { type: string },
): Block => {
  const raw = Array.isArray(text) ? text : [text];
  const children: (Text | Block)[] = raw.filter(isDefined);

  return {
    type: opts?.type || "paragraph",
    children,
  };
};

export const makeExecutionBlock = (
  text: Block | Text | (Block | Text | undefined)[],
  opts?: {
    type: string;
    signatories: { name: string; idDocument: string; idNumber: string }[];
  },
): Block => {
  const raw = Array.isArray(text) ? text : [text];
  const children: (Text | Block)[] = raw.filter(isDefined);

  return {
    type: opts?.type || "execution-block",
    children,
    signatories: opts?.signatories || [],
  };
};

export const makeWitnessBlock = (
  signatories: { name: string; idDocument: string; idNumber: string }[],
  opts?: { type: string },
): Block => {
  return {
    type: opts?.type || "paragraph",
    signatories,
    children: [{ text: "" }],
  };
};

export const makeText = (
  text: string,
  opts?: { bold?: boolean; space?: "start" | "end" | "both"; period?: boolean },
): Text => {
  const { period, space, ...rest } = opts || {};

  if (period) {
    text = `${text}.`;
  }
  if (space === "start") {
    text = ` ${text}`;
  } else if (space === "end") {
    text = `${text} `;
  } else if (space === "both") {
    text = ` ${text} `;
  }
  return {
    text,
    ...rest,
  };
};

export const makeDefinition = (
  text: string | string[],
  opts?: { space?: "start" | "end" | "both"; period?: boolean },
): Text[] => {
  const { period, space, ...rest } = opts || {};

  let startBracket = `(`;
  let endBracket = `)`;

  if (period) {
    endBracket = `${endBracket}.`;
  }

  if (space === "start") {
    startBracket = ` ${startBracket}`;
  } else if (space === "end") {
    endBracket = `${endBracket} `;
  } else if (space === "both") {
    startBracket = ` ${startBracket}`;
    endBracket = `${endBracket} `;
  }

  const between = Array.isArray(text)
    ? [
        {
          text: `${text[0]} "`,
        },
        {
          text: text[1] || "Err",
          bold: true,
        },
        {
          text: `", ${text[2] || `Err`} "`,
        },
        {
          text: text[3] || "Err",
          bold: true,
        },
        {
          text: `"`,
        },
      ]
    : [
        {
          text: `"`,
        },
        {
          text,
          bold: true,
        },
        {
          text: `"`,
        },
      ];

  return [makeText(startBracket), ...between, makeText(endBracket)];
};

export const makeSubListBlock = (blocks: Block[]): Block => {
  return makeBlock(
    blocks.map((b, idx, arr) => {
      const total = arr.length;
      const { children, ...opts } = b;
      if (idx === total - 2) {
        return makeBlock([...children, makeText("; and")], opts);
      } else if (idx === total - 1) {
        return makeBlock([...children, makeText(".")], opts);
      } else {
        if (total === 1) {
          return makeBlock([...children, makeText(".")], opts);
        }
        return makeBlock([...children, makeText(";")], opts);
      }
    }),
    { type: "ordered-list" },
  );
};
