import React from "react";
import { type PageProps } from "gatsby";

interface LayoutProps extends Omit<PageProps, "children"> {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const NoopLayout: React.FC<LayoutProps> = ({ children }) => {
  return children;
};
