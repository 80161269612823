import { TRPCClientError, TRPCClientErrorLike } from "@trpc/client";
import { H } from "highlight.run";

export const logError = (
  message: string,
  component: string,
  error: Error | TRPCClientError<any> | TRPCClientErrorLike<any>,
  opts?: Record<string, string>,
) => {
  if (error instanceof Error) {
    H.consumeError(error, message, {
      component,
      ...opts,
    });
  } else {
    H.consumeError(new Error(`${error.message}`, { cause: error }), message, {
      component,
      location: window.location.pathname,
      ...opts,
    });
  }
};
