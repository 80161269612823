import React from "react";
import { useSession } from "@/hooks/useSession";
import { Link, type PageProps } from "gatsby";
import { ROLES } from "schema";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "ui/components/dropdown-menu";

import { userNavigation } from "../const";

interface LayoutProps extends Omit<PageProps, "children"> {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const ProfileDropdown: React.FC<LayoutProps> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, isLoading } = useSession();

  if (isLoading || !data) {
    return null;
  }

  const isAdmin = data?.user?.roles.includes(ROLES.ADMIN);

  const url = new URL(process.env.GATSBY_SERVER_URL || "");

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {userNavigation.map((item) => {
            const href =
              typeof item.href === "function"
                ? item.href(data?.user._id)
                : item.href;
            return (
              <Link to={href} key={href}>
                <DropdownMenuItem className="flex items-center">
                  <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
                    <span className="material-symbols-outlined">
                      {item.icon}
                    </span>
                  </div>
                  <span>{item.name}</span>
                </DropdownMenuItem>
              </Link>
            );
          })}
          {isAdmin && (
            <Link to="/admin/users/">
              <DropdownMenuItem>
                <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
                  <span className="material-symbols-outlined">
                    shield_person
                  </span>
                </div>
                <span>Admin Portal</span>
              </DropdownMenuItem>
            </Link>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <a href={`${url.protocol}//${url.host}/auth/logout/`}>
          <DropdownMenuItem>
            <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
              <span className="material-symbols-outlined">logout</span>
            </div>
            <span>Log out</span>
          </DropdownMenuItem>
        </a>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
