import { type calendar_v3 } from "googleapis";

import { z } from "./xzod";

export type BusySlot = calendar_v3.Schema$TimePeriod;

export enum GL_CALENDARS {
  ALVIN = "alvin@guardianlaw.com.sg",
  LIANE = "liane@guardianlaw.com.sg",
  AMANDA = "amanda@guardianlaw.com.sg",
  NO_REPLY = "no-reply@guardianlaw.com.sg",
  ENQUIRIES = "enquiries@guardianlaw.com.sg",
}

export enum WC_CALENDARS {
  ALVIN = "alvin@willcraftnow.com",
  LIANE = "liane@willcraftnow.com",
  AMANDA = "amanda@willcraftnow.com",
  NO_REPLY = "no-reply@willcraftnow.com",
  ENQUIRIES = "enquiries@willcraftnow.com",
}

export const CONSULTATION_TYPES = {
  IN_PERSON: "In-person",
  VIRTUAL: "Virtual - via Google Meets",
};

const confirmCalendarSlotSchemaProperties = {
  eventId: z.string(),
  calendarId: z.enum(["alvin@guardianlaw.com.sg", "liane@guardianlaw.com.sg"]),
  email: z.string().min(3),
};

export const ConfirmConsultationSchema = z.object(
  confirmCalendarSlotSchemaProperties,
);

export const BookConsultationSchema = z.object({
  name: z.string().min(3).max(50).meta({ placeholder: "Elizabeth Chan" }),
  email: z.string().meta({ placeholder: "hello@example.com" }).min(3).max(50),
  contactNumber: z
    .string()
    .trim()
    .min(3)
    .max(50)
    .default("+65")
    .meta({ placeholder: "+65 1234 5678" }),
  meetingType: z
    .nativeEnum(CONSULTATION_TYPES)
    .default(CONSULTATION_TYPES.VIRTUAL),
  consultationTopic: z.string().default(CONSULTATION_TYPES.VIRTUAL),
  calendarId: z
    .enum(["alvin@guardianlaw.com.sg", "liane@guardianlaw.com.sg"])
    .meta({ hidden: true })
    .default("liane@guardianlaw.com.sg"),
  startTime: z.date({ invalid_type_error: "Please select a slot." }),
  endTime: z.date({ invalid_type_error: "Please select a slot." }),
});

export const ExtendedBookConsultationSchema = BookConsultationSchema.extend({
  title: z.string().min(3).max(50),
  description: z.string().min(3).max(50),
});
