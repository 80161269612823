// @ts-nocheck
import countries from "country-list";

import {
  FINRegex,
  GENDER,
  IDENTIFICATION_DOCUMENT_TYPES_ENUM,
  MARTIAL_STATUS,
  NRICRegex,
} from "../constants";
import { z } from "../xzod";

/**
 * User Details Schema
 */

export const UserDetailsSchemaProperties = {
  name: z.string().trim().describe("Full Name, Per Identification Document."),
  identificationDocument: z
    .nativeEnum(IDENTIFICATION_DOCUMENT_TYPES_ENUM)
    .default(IDENTIFICATION_DOCUMENT_TYPES_ENUM.NRIC),
  countryOfIssue: z
    .string()
    .default("sg")
    .describe("The country of issue of your Identification Document.")
    .meta({ choiceMap: countries.getCodeList() }),
  identificationNumber: z
    .string()
    .trim()
    .describe("Per your Identification Document."),
  age: z.coerce.number().min(0).default(18),
  gender: z.nativeEnum(GENDER).default(GENDER.MALE),
  martialStatus: z.nativeEnum(MARTIAL_STATUS).default(MARTIAL_STATUS.SINGLE),
  address: z.string().trim(),
  unitNumber: z
    .string()
    .trim()
    .describe("Please include your unit number where applicable."),
  email: z.string().email().trim(),
  contactNumber: z.string().trim(),
};

export const withValidation = <T extends typeof UserDetailsSchema>(
  BaseUserDetailsSchema: T,
) => {
  return BaseUserDetailsSchema.extend({
    name: UserDetailsSchemaProperties.name
      .trim()
      .min(1, "Name cannot be empty."),
    identificationNumber: UserDetailsSchemaProperties.identificationNumber
      .trim()
      .min(1, "Identification Number cannot be empty."),
    address: UserDetailsSchemaProperties.address
      .trim()
      .min(1, "Address cannot be empty."),
    contactNumber: UserDetailsSchemaProperties.contactNumber
      .trim()
      .min(1, "Contact Number cannot be empty."),
    email: UserDetailsSchemaProperties.email.min(
      1,
      "Please provide a valid email.",
    ),
  }).superRefine((val, ctx) => {
    if (
      val.identificationDocument === IDENTIFICATION_DOCUMENT_TYPES_ENUM.NRIC
    ) {
      const result = NRICRegex.test(val.identificationNumber || "");
      if (!result) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid NRIC number.",
          path: ["identificationNumber"],
        });
      }
    } else if (
      val.identificationDocument === IDENTIFICATION_DOCUMENT_TYPES_ENUM.FIN
    ) {
      const result = FINRegex.test(val.identificationNumber || "");
      if (!result) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid FIN number.",
          path: ["identificationNumber"],
        });
      }
    } else if (
      val.identificationDocument === IDENTIFICATION_DOCUMENT_TYPES_ENUM.BCS
    ) {
      const result = NRICRegex.test(val.identificationNumber || "");
      if (!result) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid FIN number.",
          path: ["identificationNumber"],
        });
      }
    }
  });
};
