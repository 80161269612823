import { createWSClient, httpBatchLink, splitLink, wsLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import type { AppRouter } from "server";
import superjson from "superjson";

global.WebSocket = global.WebSocket || null;

export const api = createTRPCReact<AppRouter>();

export const getClient = () => {
  const url = new URL(process.env.GATSBY_SERVER_URL || "");
  const isSecure = url.protocol === "https:";
  const urlEnd = `${url.host}/trpc/`;

  const batchLink = httpBatchLink({
    url: `${url.protocol}//${urlEnd}`,
    // You can pass any HTTP headers you wish here
    fetch(url, options) {
      return fetch(url, {
        ...options,
        credentials: "include",
      });
    },
    maxURLLength: 2083,
    async headers(_headers) {
      const headers = new Map();
      headers.set("Access-Control-Allow-Origin", url.hostname);
      return Object.fromEntries(headers);
    },
  });

  if (typeof window === "undefined") {
    return api.createClient({
      transformer: superjson,
      links: [batchLink],
    });
  }

  return api.createClient({
    transformer: superjson,
    links: [
      splitLink({
        condition(op) {
          return op.type === "subscription";
        },
        true: wsLink({
          client: createWSClient({
            url: `${isSecure ? "wss:" : "ws:"}//${urlEnd}`,
          }),
        }),
        false: batchLink,
      }),
    ],
  });
};
