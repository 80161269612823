import "tailwind/globals.css";

import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

import { H } from "highlight.run";

import { GlobalLayout } from "./src/layouts/global";
import { QueryProvider } from "./src/QueryProvider";

global.Websocket = global.Websocket || null;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return (
    <QueryProvider>
      <GlobalLayout {...props}>{element}</GlobalLayout>
    </QueryProvider>
  );
};

export const onClientEntry = (_, pluginOptions) => {

  const server_url = new URL(process.env.GATSBY_SERVER_URL || '');

  H.init(process.env.GATSBY_HIGHLIGHT_ID, {
    serviceName: "frontend-app",
    tracingOrigins: [server_url.host],
    environment: process.env.NODE_ENV,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [process.env.GATSBY_APP_URL || ''],
    },
    reportConsoleErrors: true,
  });

  // @ts-ignore
  window.H = Highlight.H;
};
