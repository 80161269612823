import * as React from "react";
import {
  PasswordContextProvider,
  PasswordPlugin,
} from "@/forms/plugins/Password";
import { navigate, type HeadFC, type PageProps } from "gatsby";
import { AUTH_ERROR_MESSAGES, EMAIL_MODES, z } from "schema";
import { Alert, AlertDescription, AlertTitle } from "ui/components/alert";
import { Button } from "ui/components/button";
import { SubmitButton, useZodForm, ZForm } from "ui/components/form-z";
import { Icon } from "ui/components/icon";
import { Input } from "ui/components/input";
import { Label } from "ui/components/label";
import { Separator } from "ui/components/separator";
import { useToast } from "ui/components/use-toast";

import { api } from "../api";

export const LoginForm: React.FC<{ email: string }> = ({ email }) => {
  const { toast } = useToast();

  const LoginSchema = z.object({
    email: z.string().email().meta({ disabled: true }),
    password: z.string(),
  });

  const loginForm = useZodForm({
    schema: LoginSchema,
    defaultValues: { email },
    id: "login-form",
  });

  const utils = api.useContext().user.getSession;

  const { mutateAsync: loginMutateAsync } = api.user.login.useMutation({
    async onSuccess(data, variables, context) {
      await utils.invalidate();
      toast({
        title: "Welcome Back!",
        description: `Logged In Successfully.`,
      });
      navigate("/");
    },
    onError: async (e) => {
      toast({
        // itemID: "auth-error",
        title: "Uhoh!",
        description: `${e?.message}`,
      });
    },
  });

  return (
    <PasswordContextProvider>
      <ZForm
        form={loginForm}
        plugins={[PasswordPlugin({ fieldName: "password" })]}
        handleSubmit={async (data) => {
          await loginMutateAsync(data);
        }}
      />
      <SubmitButton
        className="w-full"
        form={loginForm}
        data-testid={"submit-btn"}
      >
        Continue with password
      </SubmitButton>
    </PasswordContextProvider>
  );
};

export const CodeForm: React.FC<{
  email: string;
  mode: EMAIL_MODES | undefined;
  currentPath: string;
}> = ({ email, mode, currentPath }) => {
  const { toast } = useToast();

  const CodeSchema = z.object({
    email: z.string().email().meta({ disabled: true }),
    code: z.string(),
  });

  const codeForm = useZodForm({
    schema: CodeSchema,
    defaultValues: { email },
    id: "code-form",
  });

  const utils = api.useContext().user.getSession;

  const { mutateAsync: codeLoginMutateAsync } = api.user.codeLogin.useMutation({
    async onSuccess(data, variables, context) {
      await utils.invalidate();
      toast({
        title: "Welcome to WillCraft!",
        description: `Registered Successfully.`,
      });
      navigate(currentPath ? currentPath : "/");
    },
    onError: async (e) => {
      const message = e?.message;
      if (message === AUTH_ERROR_MESSAGES.NOT_FOUND) {
        toast({
          // itemID: "auth-error",
          title: "Uhoh!",
          description: `${e?.message} Please login.`,
        });
      }
      toast({
        // itemID: "auth-error",
        title: "Uhoh!",
        description: `${e?.message}`,
      });
    },
  });
  return (
    <>
      <ZForm
        form={codeForm}
        plugins={[]}
        handleSubmit={async (data) => {
          await codeLoginMutateAsync(data);
        }}
      />
      <SubmitButton
        className="w-full"
        form={codeForm}
        data-testid={"submit-btn"}
      >
        {mode === EMAIL_MODES.DOES_NOT_EXIST
          ? "Create new Account"
          : "Continue With Code"}
      </SubmitButton>
    </>
  );
};

export const EmailAuthPage: React.FC<{ currentPath: string }> = ({
  currentPath,
}) => {
  const [mode, setMode] = React.useState<null | {
    mode:
      | EMAIL_MODES.EXISTS_WITHOUT_PASSWORD
      | EMAIL_MODES.EXISTS_WITH_PASSWORD
      | EMAIL_MODES.DOES_NOT_EXIST;
    email: string;
  }>(null);

  const { toast } = useToast();

  const EmailSchema = z.object({
    email: z
      .string()
      .email()
      .transform((val) => val.toLowerCase()),
  });

  const emailForm = useZodForm({
    schema: EmailSchema,
    defaultValues: {},
    id: "email-form",
  });

  const { mutateAsync: emailMutateAsync } = api.user.checkEmail.useMutation({
    onError: async (e) => {
      console.error(e);
      toast({
        title: "Uhoh!",
        description: `Something went wrong. Please try again, or contact us directly if the issue persists`,
      });
    },
  });

  return (
    <div>
      {[
        EMAIL_MODES.EXISTS_WITHOUT_PASSWORD,
        EMAIL_MODES.DOES_NOT_EXIST,
      ].includes(mode?.mode || ("" as EMAIL_MODES)) && (
        <CodeForm
          email={mode?.email || ""}
          mode={mode?.mode}
          currentPath={currentPath}
        />
      )}
      {mode?.mode === EMAIL_MODES.EXISTS_WITH_PASSWORD && (
        <LoginForm email={mode.email} />
      )}
      {!mode && (
        <>
          <ZForm
            form={emailForm}
            plugins={[]}
            handleSubmit={async (data) => {
              const { mode, success } = await emailMutateAsync(data);
              setMode({ mode, email: data.email });
              if (
                mode === EMAIL_MODES.EXISTS_WITHOUT_PASSWORD ||
                mode === EMAIL_MODES.DOES_NOT_EXIST
              )
                toast({
                  title: "Please check your email inbox!",
                  description:
                    "A vertification code has been sent to your email. Please enter the code to continue.",
                });
            }}
          />
          <SubmitButton
            className="w-full"
            form={emailForm}
            data-testid={"submit-btn"}
          >
            Continue with email
          </SubmitButton>
        </>
      )}
    </div>
  );
};

const AuthPage: React.FC<Omit<PageProps, "children">> = (props) => {
  const currentPath = props?.location?.pathname;
  const redirectPath = currentPath
    ? encodeURIComponent(currentPath)
    : undefined;

  const url = new URL(process.env.GATSBY_SERVER_URL || "");
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center space-y-10 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-4xl font-bold leading-9 tracking-tight text-foreground">
          Log In
        </h2>
      </div>
      <div className="min-w-[320px] space-y-10">
        <Button asChild>
          <a
            href={
              redirectPath
                ? `${url.protocol}//${url.host}/auth/google/?redirectPath=${redirectPath}`
                : `${url.protocol}//${url.host}/auth/google/`
            }
            className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              className="h-5 w-5"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M 26 2 C 13.308594 2 3 12.308594 3 25 C 3 37.691406 13.308594 48 26 48 C 35.917969 48 41.972656 43.4375 45.125 37.78125 C 48.277344 32.125 48.675781 25.480469 47.71875 20.9375 L 47.53125 20.15625 L 46.75 20.15625 L 26 20.125 L 25 20.125 L 25 30.53125 L 36.4375 30.53125 C 34.710938 34.53125 31.195313 37.28125 26 37.28125 C 19.210938 37.28125 13.71875 31.789063 13.71875 25 C 13.71875 18.210938 19.210938 12.71875 26 12.71875 C 29.050781 12.71875 31.820313 13.847656 33.96875 15.6875 L 34.6875 16.28125 L 41.53125 9.4375 L 42.25 8.6875 L 41.5 8 C 37.414063 4.277344 31.960938 2 26 2 Z M 26 4 C 31.074219 4 35.652344 5.855469 39.28125 8.84375 L 34.46875 13.65625 C 32.089844 11.878906 29.199219 10.71875 26 10.71875 C 18.128906 10.71875 11.71875 17.128906 11.71875 25 C 11.71875 32.871094 18.128906 39.28125 26 39.28125 C 32.550781 39.28125 37.261719 35.265625 38.9375 29.8125 L 39.34375 28.53125 L 27 28.53125 L 27 22.125 L 45.84375 22.15625 C 46.507813 26.191406 46.066406 31.984375 43.375 36.8125 C 40.515625 41.9375 35.320313 46 26 46 C 14.386719 46 5 36.609375 5 25 C 5 13.390625 14.386719 4 26 4 Z" />
            </svg>
            <span className="text-sm font-semibold leading-6">Google</span>
          </a>
        </Button>

        <Separator />
        <EmailAuthPage currentPath={currentPath} />
      </div>
    </div>
  );
};

export default AuthPage;

export const Head: HeadFC = () => <title>Home Page</title>;
