import React, { useEffect, useState } from "react";
import { useSession } from "@/hooks/useSession";
import { Link, type PageProps } from "gatsby";
import { H } from "highlight.run";
import { Icon } from "ui/components/icon";
import { WillCraftLogo } from "ui/components/logo";
import { SmallLoader } from "ui/components/small-loader";
import { Toaster } from "ui/components/toaster";

import AuthPage from "../components/AuthPage";
import { ErrorPane } from "@/components/ErrorPane";
import { AdminLayout } from "./admin";
import { NoopLayout } from "./noop";
import { ProfileDropdown } from "./profile-dropdown";

interface LayoutProps extends Omit<PageProps, "children"> {
  children: React.ReactElement;
}

interface WrapperLayoutProps extends LayoutProps {
  session: ReturnType<typeof useSession>["data"];
}

const GlobalBanner: React.FC = () => {
  const [showBanner, setShowBanner] = React.useState(
    localStorage.getItem("showBanner") !== "false",
  );

  const handleDismiss = () => {
    setShowBanner(false);
    localStorage.setItem("showBanner", "false");
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-6 bg-primary/90 px-6 py-2.5 print:hidden sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <>
          <strong className="font-semibold">App Upgrade!</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx="1" cy="1" r="1" />
          </svg>
        </>
        We've worked hard to deliver a brand new experience for you. We hope
        you'll enjoy this new version of WillCraft.
      </p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={() => handleDismiss()}
        >
          <span className="sr-only">Dismiss</span>
          <Icon
            icon="close"
            size="lg"
            className="text-background"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
};

const GlobalWrapper: React.FC<WrapperLayoutProps> = ({
  children,
  session,
  ...props
}) => {
  const isLoggedIn = !!session?.user?._id;
  const throttleRef = React.useRef(false);
  const lastScrollRef = React.useRef(0);
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    function onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition <= 0) {
        lastScrollRef.current = 0;

        setShow(true);
      } else {
        if (currentScrollPosition > lastScrollRef.current) {
          setShow(false);
        } else {
          setShow(true);
        }

        lastScrollRef.current = currentScrollPosition;
      }
    }

    function onScrollThrottled() {
      if (!throttleRef.current) {
        throttleRef.current = true;
        requestAnimationFrame(() => {
          onScroll();
          throttleRef.current = false;
        });
      }
    }

    window.addEventListener("scroll", onScrollThrottled, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScrollThrottled);
    };
  }, []);

  return (
    <div className="flex min-h-screen flex-col">
      <GlobalBanner />
      <div
        className="sticky top-0 z-50 mx-auto h-20 w-full max-w-7xl border-none px-4 data-[show=false]:-z-10 print:hidden sm:px-6 lg:px-8"
        data-show={show}
      >
        <nav
          className="flex items-center justify-between bg-white ease-in-out animate-in slide-in-from-top slide-out-to-top data-[show=false]:hidden"
          data-show={show}
        >
          <div className="flex">
            <Link
              to="/"
              className="y-3 my-3 flex flex-shrink-0 items-center rounded-2xl bg-background/5 bg-clip-padding px-0 py-1  backdrop-blur-sm backdrop-filter md:px-3"
            >
              <WillCraftLogo />
            </Link>
          </div>
          <div className="flex h-full space-x-4 sm:ml-6 sm:items-center">
            {isLoggedIn ? (
              <ProfileDropdown {...props}>
                <button className="relative inset-1 inline-flex aspect-square max-w-xs  flex-shrink-0 items-center justify-center rounded-full bg-background/5 bg-clip-padding px-1.5 text-sm text-muted-foreground ring-muted-foreground backdrop-blur-sm backdrop-filter hover:font-bold hover:text-foreground hover:ring-1">
                  <span className="sr-only">Open user menu</span>
                  <Icon
                    icon="person"
                    className="aspect-square h-8 w-8 rounded-full"
                    size={"xl"}
                  />
                </button>
              </ProfileDropdown>
            ) : (
              <Link
                to="/auth/"
                className="flex-shrink-1 rounded- relative inline-flex  max-w-xs items-center justify-center rounded-lg border bg-background/5 bg-clip-padding px-3 py-2 text-base font-bold text-muted-foreground ring-muted-foreground backdrop-blur-sm backdrop-filter hover:text-foreground hover:ring-1"
              >
                Log In
              </Link>
            )}
          </div>
        </nav>
      </div>
      <main className="flex grow">
        <div className="mx-auto flex max-w-7xl grow px-4 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>
      <Toaster />
    </div>
  );
};

export const GlobalLayout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const { path } = props;
  const isAdminPath = path.includes("/admin/");
  const { data, error, isLoading } = useSession();

  useEffect(() => {
    if (data?.user) {
      // @ts-ignore
      H.identify(data.user._id, {
        id: data.user._id,
        // emails: data.user.emails.map((e) => e.email),
      });
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="grid h-screen place-content-center">
        <p className="pb-10 font-bold tracking-wide lg:text-xl">
          Loading WillCraft
        </p>
        <SmallLoader />
      </div>
    );
  }

  if (error) {
    return (
      <GlobalWrapper {...props} session={data}>
        <ErrorPane error={error} />
      </GlobalWrapper>
    );
  }

  if (!data?.user) {
    return (
      <GlobalWrapper {...props} session={data}>
        <div className="mx-auto w-fit">
          <AuthPage {...props} />
        </div>
      </GlobalWrapper>
    );
  }

  let SubLayoutComponent = NoopLayout;

  if (isAdminPath) {
    SubLayoutComponent = AdminLayout;
  }

  return (
    <GlobalWrapper {...props} session={data}>
      <SubLayoutComponent {...props}>{children}</SubLayoutComponent>
    </GlobalWrapper>
  );
};
