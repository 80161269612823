import * as React from "react";
import { logError } from "@/utils";
import { H } from "highlight.run";
import { useToast } from "ui/components/use-toast";

import { api } from "../api";

export const useSession = () => {
  const { toast } = useToast();
  const { data, error, isError, isInitialLoading } =
    api.user.getSession.useQuery(undefined, {
      cacheTime: 0,
      onError: (error) => {
        logError("Auth Error:", "useSession", error);
        toast({
          itemID: "auth-error",
          title: "Uhoh!",
          description: `Something Went Wrong. Please contact customer support. ${error.message}`,
        });
      },
    });

  return {
    data,
    error,
    isLoading: isInitialLoading,
    isError,
    isLoggedIn: !!data?.user && !isInitialLoading,
  };
};
