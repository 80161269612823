import { z } from "../xzod";
import { UserResourceDetailsSchema } from "./index";

export const UserDetailsTitleTransform = <
  T extends typeof UserResourceDetailsSchema,
>(
  data: z.infer<T>,
) =>
  `${data.name} (${
    data.identificationDocument
  } No. ${data.identificationNumber.substring(
    data.identificationNumber.length - 4,
    data.identificationNumber.length,
  )})`;

export const UserDetailsDefinitionTransform = <
  T extends typeof UserResourceDetailsSchema,
>(
  data: z.infer<T>,
) => `${data.name}`;

export const UserDetailsDefineTransform = <
  T extends typeof UserResourceDetailsSchema,
>(
  data: z.infer<T>,
) =>
  `${data.name} (${data.identificationDocument} No. ${
    data.identificationNumber
  }) of ${[data.unitNumber, data.address].filter(Boolean).join(" ")}`;
