import React from "react";
import { Button } from "ui/components/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useFormContext,
} from "ui/components/form";
import {
  CallOut,
  getSchema,
  IZFormFieldProps,
  ZFormDescription,
  ZFormLabel,
  ZodFormPlugin,
} from "ui/components/form-z";
import { Icon } from "ui/components/icon";
import { Input } from "ui/components/input";

type TPluginProps = {
  fieldName: string;
};

export const PasswordContext = React.createContext<{
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  show: false,
  setShow: () => {},
});

export const PasswordContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [show, setShow] = React.useState<boolean>(false);
  return (
    <PasswordContext.Provider value={{ show, setShow }}>
      {children}
    </PasswordContext.Provider>
  );
};

export const PasswordPlugin: ZodFormPlugin =
  ({ fieldName }: TPluginProps) =>
  (props) => {
    if (props.fieldName !== fieldName) return;

    const UserHandleField = (props: IZFormFieldProps) => {
      const { show, setShow } = React.useContext(PasswordContext);
      const methods = useFormContext();
      const identifier = [...props.identifierTrace, props.fieldName].join(".");

      const formDisabled =
        methods.formState.isSubmitting || methods.formState.isLoading;

      return (
        <FormField
          control={methods.control}
          shouldUnregister={true}
          name={identifier}
          defaultValue={props.defaultValueSchema?._def.defaultValue()}
          render={({ field }) => (
            <FormItem id={identifier}>
              <ZFormLabel {...props} />
              <CallOut callouts={getSchema(props.schema)._def.callouts} />
              <FormControl>
                <div className="flex h-9 w-full flex-row rounded-md border align-middle shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-border focus-within:ring-offset-1">
                  <Input
                    className="border-none shadow-none ring-0 focus-visible:ring-0"
                    placeholder={getSchema(props.schema)._def.placeholder}
                    disabled={
                      formDisabled || getSchema(props.schema)._def.disabled
                    }
                    {...field}
                    type={show ? "password" : "text"}
                    data-testid={identifier}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => setShow((s) => !s)}
                    className="grid grid-cols-1 place-content-center hover:bg-transparent hover:text-foreground"
                    data-show={show}
                  >
                    <Icon
                      icon={show ? "visibility" : "visibility_off"}
                      className="text-foreground/50"
                    />
                  </Button>
                </div>
              </FormControl>
              <ZFormDescription {...props} />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    };
    return React.useCallback(UserHandleField, []);
  };
