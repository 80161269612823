import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { api, getClient } from "./api";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000,
    },
  },
});

const trpcClient = getClient();

export const QueryProvider = ({ children }: { children: any }) => {
  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </api.Provider>
  );
};
