import * as React from "react";
import { Link } from "gatsby";
import { cn } from "tailwind/utils";
import { Icon } from "ui/components/icon";

type DashboardLinkProps = {
  name: string;
  href: string;
  className?: string;
  activeClassName?: string;
  as?: string;
  icon?: string;
};

export const DashboardLink: React.FC<DashboardLinkProps> = ({
  href,
  name,
  className,
  activeClassName,
  icon,
}) => {
  return (
    <span>
      <Link
        to={href}
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          return {
            className: cn(
              className,
              isPartiallyCurrent || isCurrent ? activeClassName : "",
            ),
          };
        }}
        partiallyActive={true}
      >
        <Icon icon={icon} size="lg" />
        <span>{name}</span>
      </Link>
    </span>
  );
};
