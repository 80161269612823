import { ORDER_TYPE, OrderStatusEnum } from "../constants";
import {
  UserAssetResourceArraySchema,
  UserEntityResourceArraySchema,
  UserResourceDetailsSchema,
} from "../user-resource";
import { z } from "../xzod";
import { LegacyLPAFormSchema } from "./legacy-lpa";
import { LPAFormSchema } from "./lpa";
import {
  BaseValidatedWillFormSchema,
  ValidatedWillFormSchema,
  WillFormSchema,
} from "./will/schema";

export * from "./lpa";
export * from "./legacy-lpa";
export * from "./finalValidation";
export * from "./will";
export * from "./pricing";
/**
 * Order Schema
 */

export const OrderBaseSchema = z.object({
  status: z.nativeEnum(OrderStatusEnum).default(OrderStatusEnum.DRAFT),
  orderNumber: z.string().optional(),
  expiryDays: z.number().optional(),
  type: z.nativeEnum(ORDER_TYPE),
  userId: z.string().min(1),
  _id: z.string().min(1),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const CompletedBaseSchema = z.object({
  entities: UserEntityResourceArraySchema,
  assets: UserAssetResourceArraySchema,
  user: UserResourceDetailsSchema,
  purchasedAt: z.date().nullable(),
  sessionId: z.string(),
  invoiceId: z.string().nullable(),
});

export const CompletedLegacySchema = z.object({
  purchasedAt: z.date().nullable(),
  grossPrice: z.number(),
  nettPrice: z.number(),
  priceAfterCardFees: z.number(),
  cardFees: z.number(),
  invoices: z.array(
    z.object({
      key: z.string(),
      contentType: z.string(),
    }),
  ),
  documents: z.array(
    z.object({
      key: z.string(),
      contentType: z.string(),
    }),
  ),
});

export const BaseOrderDataSchemaMap = {
  [ORDER_TYPE.WILL]: WillFormSchema,
  [ORDER_TYPE.LPA]: LPAFormSchema,
  [ORDER_TYPE.LEGACY_LPA]: LegacyLPAFormSchema,
};

export const ValidatedOrderDataSchemaMap = {
  [ORDER_TYPE.WILL]: ValidatedWillFormSchema,
  [ORDER_TYPE.LPA]: LPAFormSchema,
  [ORDER_TYPE.LEGACY_LPA]: LegacyLPAFormSchema,
};

export const OrderDocumentSchema = z.discriminatedUnion("type", [
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.WILL),
      completed: z.array(
        CompletedBaseSchema.extend({ resource: WillFormSchema }),
      ),
      data: ValidatedWillFormSchema,
    }),
  ),
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.LPA),
      completed: z.array(
        CompletedBaseSchema.extend({ resource: WillFormSchema }),
      ),
      data: ValidatedWillFormSchema,
    }),
  ),
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.LEGACY_WILL),
      completed: z.array(CompletedLegacySchema),
      data: ValidatedWillFormSchema,
    }),
  ),
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.LEGACY_LPA),
      completed: z.array(CompletedLegacySchema),
      data: LegacyLPAFormSchema,
    }),
  ),
]);

export const PartialOrderDocumentSchema = z.discriminatedUnion("type", [
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.WILL),
      completed: z.array(
        CompletedBaseSchema.extend({ resource: WillFormSchema }),
      ),
      data: BaseValidatedWillFormSchema,
    }),
  ),
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.LPA),
      completed: z.array(
        CompletedBaseSchema.extend({ resource: WillFormSchema }),
      ),
      data: LPAFormSchema,
    }),
  ),
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.LEGACY_WILL),
      completed: z.array(CompletedLegacySchema),
      details: z.object({
        entities: UserEntityResourceArraySchema,
        assets: UserAssetResourceArraySchema,
        user: UserResourceDetailsSchema,
      }),
      data: ValidatedWillFormSchema,
    }),
  ),
  OrderBaseSchema.merge(
    z.object({
      type: z.literal(ORDER_TYPE.LEGACY_LPA),
      completed: z.array(CompletedLegacySchema),
      details: z.object({
        entities: UserEntityResourceArraySchema,
        assets: UserAssetResourceArraySchema,
        user: UserResourceDetailsSchema,
      }),
      data: LegacyLPAFormSchema,
    }),
  ),
]);
