import { z } from "zod";

import { ORDER_TYPE } from "../constants";
import {
  ASSET_TYPES_ENUM,
  EntityTypeEnum,
  UserResourceAssetSchema,
  UserResourceDetailsSchema,
  UserResourceEntitySchema,
} from "../user-resource";
import { LPAFormSchema } from "./lpa";
import {
  ALLOCATION_MODE_ENUM,
  AmountOnlyAllocationsSchema,
  AmountOnlySimpleAllocationsSchema,
  AssetAllocationTypeEnum,
  PercentageOnlyAllocationsSchema,
  PercentageOnlySimpleAllocationsSchema,
  ValidatedWillFormSchema,
} from "./will";

function joinWithAnd(lst: string[]) {
  if (lst.length === 0) {
    return "";
  } else if (lst.length === 1) {
    return lst[0];
  } else {
    return lst.slice(0, -1).join(", ") + " and " + lst[lst.length - 1];
  }
}

type CheckCalAppointmentExists = (id: string) => Promise<boolean>;

export const finalAssetResourceValidations =
  ({
    assets,
  }: {
    assets: z.infer<typeof UserResourceAssetSchema>[];
    entities: z.infer<typeof UserResourceEntitySchema>[];
    details: z.infer<typeof UserResourceDetailsSchema>;
  }) =>
  (data: z.infer<typeof UserResourceAssetSchema>, ctx: z.RefinementCtx) => {
    const isEditing = !!data._id;

    switch (data.type) {
      case ASSET_TYPES_ENUM.BANK_ACCOUNT: {
        const duplicateBankAccount = assets.find(
          (a) =>
            a.type === ASSET_TYPES_ENUM.BANK_ACCOUNT &&
            a.accountNumber === data.accountNumber &&
            a.bankName === data.bankName,
        );

        console.log({ duplicateBankAccount, data });
        if (
          duplicateBankAccount &&
          (!isEditing || duplicateBankAccount._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Bank account with the same account number and bank name already exists.`,
            path: ["root.server"],
          });
        }
        break;
      }
      case ASSET_TYPES_ENUM.REAL_ESTATE: {
        const duplicateRealEstate = assets.find(
          (a) =>
            a.type === ASSET_TYPES_ENUM.REAL_ESTATE &&
            a.address === data.address,
        );
        if (
          duplicateRealEstate &&
          (!isEditing || duplicateRealEstate._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Real estate with the same address already exists.`,
            path: ["root.server"],
          });
        }
        break;
      }
      case ASSET_TYPES_ENUM.COMPANY: {
        const duplicateCompany = assets.find(
          (a) =>
            a.type === ASSET_TYPES_ENUM.COMPANY &&
            a.registrationNumber === data.registrationNumber &&
            a.countryOfIncorporation === data.countryOfIncorporation,
        );
        if (
          duplicateCompany &&
          (!isEditing || duplicateCompany._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Company with the same registration number and country of incorporation already exists.`,
            path: ["root.server"],
          });
        }
        break;
      }
      case ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT: {
        const duplicateInvestmentAccount = assets.find(
          (a) =>
            a.type === ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT &&
            a.investmentAccountNumber === data.investmentAccountNumber &&
            a.nameOfFinancialInstitution === data.nameOfFinancialInstitution,
        );
        if (
          duplicateInvestmentAccount &&
          (!isEditing || duplicateInvestmentAccount._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Investment account with the same account number and financial institution already exists.`,
            path: ["root.server"],
          });
        }
        break;
      }
      case ASSET_TYPES_ENUM.INSURANCE: {
        const duplicateInsurance = assets.find(
          (a) =>
            a.type === ASSET_TYPES_ENUM.INSURANCE &&
            a.insurancePolicyNumber === data.insurancePolicyNumber &&
            a.nameOfInsurer === data.nameOfInsurer,
        );
        if (
          duplicateInsurance &&
          (!isEditing || duplicateInsurance._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Insurance with the same policy number and insurer already exists.`,
            path: ["root.server"],
          });
        }
        break;
      }
      case ASSET_TYPES_ENUM.PERSONAL_ITEMS: {
        const duplicatePersonalItem = assets.find(
          (a) =>
            a.type === ASSET_TYPES_ENUM.PERSONAL_ITEMS &&
            a.shortNameOfItem === data.shortNameOfItem,
        );
        if (
          duplicatePersonalItem &&
          (!isEditing || duplicatePersonalItem._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Personal item with the same short name already exists.`,
            path: ["root.server"],
          });
        }
      }
    }
  };

export const finalEntityResourceValidations =
  ({
    entities,
    details,
  }: {
    assets: z.infer<typeof UserResourceAssetSchema>[];
    entities: z.infer<typeof UserResourceEntitySchema>[];
    details: z.infer<typeof UserResourceDetailsSchema>;
  }) =>
  (data: z.infer<typeof UserResourceEntitySchema>, ctx: z.RefinementCtx) => {
    const isEditing = !!data._id;

    switch (data.type) {
      case EntityTypeEnum.PERSON: {
        const duplicatePerson = entities.find(
          (e) =>
            e.type === EntityTypeEnum.PERSON &&
            e.identificationNumber === data.identificationNumber &&
            e.identificationDocument === data.identificationDocument,
        );

        if (
          duplicatePerson &&
          (!isEditing || duplicatePerson._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Person with the same identification document already exists.`,
            path: ["root.server"],
          });
        }

        const personIsSameAsUser =
          details.identificationDocument === data.identificationDocument &&
          details.identificationNumber === data.identificationNumber;

        if (!isEditing && personIsSameAsUser) {
          ctx.addIssue({
            code: "custom",
            message: `You may not add yourself as a person.`,
            path: ["root.server"],
          });
        }

        break;
      }
      case EntityTypeEnum.CHARITY: {
        const duplicateCharity = entities.find(
          (e) => e.type === EntityTypeEnum.CHARITY && e.UEN === data.UEN,
        );
        if (
          duplicateCharity &&
          (!isEditing || duplicateCharity._id !== data._id)
        ) {
          ctx.addIssue({
            code: "custom",
            message: `Charity with the same charity number already exists.`,
            path: ["root.server"],
          });
        }
        break;
      }
    }
  };

export const FinalOrderValidationGetters = {
  [ORDER_TYPE.LEGACY_LPA]: () => LPAFormSchema.superRefine(() => {}),
  [ORDER_TYPE.LPA]: ({
    checkCalAppointmentExists,
  }: {
    checkCalAppointmentExists: CheckCalAppointmentExists;
  }) =>
    LPAFormSchema.superRefine(async (data, ctx) => {
      if (!(await checkCalAppointmentExists(data.calendarEventId)))
        ctx.addIssue({
          code: "custom",
          message: `Calendar event does not exist. Please select a valid calendar slot.`,
          path: ["calendarEventId"],
        });
    }),
  [ORDER_TYPE.WILL]: ({
    checkCalAppointmentExists,
    assets,
    entities,
    details,
  }: {
    checkCalAppointmentExists: CheckCalAppointmentExists;
    assets: z.infer<typeof UserResourceAssetSchema>[];
    entities: z.infer<typeof UserResourceEntitySchema>[];
    details: z.infer<typeof UserResourceDetailsSchema>;
  }) =>
    ValidatedWillFormSchema.superRefine((data, ctx) => {
      const checkOfAge =
        (path: string[]) =>
        (targetId: string, index: number, array: string[]) => {
          const t = entities.find((e) => e._id === targetId);

          if (array.filter((e) => e === targetId).length > 1) {
            ctx.addIssue({
              code: "custom",
              message: `You may not appoint the same person more than once.`,
              path,
            });
          }

          if (!t) {
            ctx.addIssue({
              code: "custom",
              message: `Person ${targetId}] not found. Please remove all persons from the list and add them again.`,
              path,
            });
            return;
          }

          if (t.type !== EntityTypeEnum.PERSON) {
            ctx.addIssue({
              code: "custom",
              message: `You may not appoint a non-person entity.`,
              path,
            });
            return;
          }

          if (t.age < 18) {
            ctx.addIssue({
              code: "custom",
              message: `${t.name} is a minor. You may not appoint a minor.`,
              path,
            });
          }
        };

      const getBeneficiary = (targetId: string) =>
        entities.find((e) => e._id === targetId);

      const getBeneficiaryName = (targetId: string) => {
        const t = getBeneficiary(targetId);

        switch (t?.type) {
          case EntityTypeEnum.PERSON:
            return t.name;
          case EntityTypeEnum.CHARITY:
            return t.charityName;
          default:
            return undefined;
        }
      };

      const checkBeneficiaryExists = (path: string[]) => (targetId: string) => {
        const t = getBeneficiary(targetId);

        if (!t) {
          ctx.addIssue({
            code: "custom",
            message: `Person ${targetId}] not found.`,
            path,
          });
          return;
        }
      };

      data.executors.forEach(checkOfAge(["executors"]));
      data.substituteExecutors?.forEach(checkOfAge(["substituteExecutors"]));

      if (data.executors.length) {
        const subs = data.substituteExecutors || [];
        if (subs.length > 0) {
          const intersected = data.executors.filter((value) =>
            subs.includes(value),
          );

          const intersectedNames = intersected
            .map((id) => getBeneficiaryName(id))
            .filter((x): x is string => x !== undefined);

          if (intersected.length) {
            ctx.addIssue({
              code: "custom",
              message: [
                intersectedNames.length === 1
                  ? `${joinWithAnd(
                      intersectedNames,
                    )} is both an executor and a substitute executor.`
                  : `${joinWithAnd(
                      intersectedNames,
                    )} are both executors and substitute executors.`,
                `You may not appoint the same person as both an executor and a substitute executor.`,
              ].join(" "),
              path: ["substituteExecutors"],
            });
          }
        }
      }
      data.testamentaryGuardians.forEach(checkOfAge(["testamentaryGuardians"]));
      data.substituteTestamentaryGuardians?.forEach(
        checkOfAge(["substituteTestamentaryGuardians"]),
      );
      data.witnesses?.forEach(checkOfAge(["witnesses"]));

      const getBeneficiaryRecursive = (
        allocations:
          | z.infer<typeof PercentageOnlyAllocationsSchema>[]
          | z.infer<typeof AmountOnlyAllocationsSchema>[]
          | z.infer<typeof PercentageOnlySimpleAllocationsSchema>[]
          | z.infer<typeof AmountOnlySimpleAllocationsSchema>[],
        allBeneficiaries: string[],
        isMainAlloc: boolean = false,
        pathTrace: string[] = [],
        beneficiariesTrace: string[] = [],
      ) => {
        allocations.forEach((a, idx) => {
          allBeneficiaries.push(a.beneficiary);
          if (
            a.substituteAllocationMode ===
              ALLOCATION_MODE_ENUM.CUSTOM_PERCENT ||
            a.substituteAllocationMode === ALLOCATION_MODE_ENUM.CUSTOM_AMOUNT ||
            a.substituteAllocationMode === ALLOCATION_MODE_ENUM.EQUALLY
          ) {
            getBeneficiaryRecursive(
              a.substituteAllocations,
              allBeneficiaries,
              false,
              [
                ...pathTrace,
                isMainAlloc ? "allocations" : "substituteAllocations",
                `${idx}`,
              ],
              beneficiariesTrace.concat(a.beneficiary),
            );

            const substituteBeneficiaries = a.substituteAllocations.map(
              (s) => s.beneficiary,
            );

            if (substituteBeneficiaries.includes(a.beneficiary)) {
              ctx.addIssue({
                code: "custom",
                message: `You may not appoint a beneficiary also as a subsitute beneficiary.`,
                path: [
                  ...pathTrace,
                  isMainAlloc ? "allocations" : "substituteAllocations",
                  `${idx}`,
                  "beneficiary",
                ],
              });
            }

            if (
              substituteBeneficiaries.length !==
              new Set(substituteBeneficiaries).size
            ) {
              ctx.addIssue({
                code: "custom",
                message: `You may not appoint a substitute beneficiary more than once.`,
                path: [...pathTrace, "substituteAllocations"],
              });
            }
          }

          checkBeneficiaryExists(["distributions", "allocations"])(
            a.beneficiary,
          );

          if (beneficiariesTrace.includes(a.beneficiary)) {
            ctx.addIssue({
              code: "custom",
              message: `This beneficiary is already appointed in the same distribution path.`,
              path: [
                ...pathTrace,
                isMainAlloc ? "allocations" : "substituteAllocations",
                `${idx}`,
                "beneficiary",
              ],
            });
          }
        });
      };

      const beneficiaries: string[] = [];

      data.distributions.forEach((d, idx) => {
        if (d.assetAllocationType !== AssetAllocationTypeEnum.WHOLE) {
          getBeneficiaryRecursive(d.allocations, beneficiaries, true, [
            "distributions",
            `${idx}`,
          ]);
        } else {
          beneficiaries.push(d.beneficiary);
          checkBeneficiaryExists(["distributions", `${idx}`])(d.beneficiary);
          d.substituteBeneficiaries.forEach((sb) => {
            beneficiaries.push(sb);
            checkBeneficiaryExists(["distributions", `${idx}`])(sb);
          });

          if (d.substituteBeneficiaries.includes(d.beneficiary)) {
            ctx.addIssue({
              code: "custom",
              message: `You may not appoint a beneficiary also as a subsitute beneficiary.`,
              path: ["distributions", `${idx}`, "beneficiary"],
            });
          }

          if (
            d.substituteBeneficiaries.length !==
            new Set(d.substituteBeneficiaries).size
          ) {
            ctx.addIssue({
              code: "custom",
              message: `You may not appoint a substitute beneficiary more than once.`,
              path: ["distributions", `${idx}`, "substituteBeneficiaries"],
            });
          }
        }
      });

      //   check if beneficiaries are also witnesses
      const witnessIds = data.witnesses;
      const beneficiaryIds = beneficiaries;
      const witnessAndBeneficiaryIntersection = witnessIds?.filter((id) =>
        beneficiaryIds.includes(id),
      );

      witnessAndBeneficiaryIntersection?.forEach((witnessId) => {
        const witness = entities.find((e) => e._id === witnessId);

        if (witness && witness.type === EntityTypeEnum.PERSON) {
          ctx.addIssue({
            code: "custom",
            message: `${witness.name} is a beneficiary. You may not appoint a beneficiary as a witness.`,
            path: ["witnesses"],
          });
        } else {
          ctx.addIssue({
            code: "custom",
            message: `You may not appoint a beneficiary as a witness.`,
            path: ["witnesses"],
          });
        }
      });

      const assetIds = data.distributions.map((d) => d.asset);

      const assetIdsSet = new Set(assetIds);

      if (assetIds.length !== assetIdsSet.size) {
        ctx.addIssue({
          code: "custom",
          message: `You may not distribute the same asset more than once.`,
        });
      }
    }),
};
