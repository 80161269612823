import { AsYouType } from "libphonenumber-js";

import { z } from "../xzod";
import { getUserAssetResourceSchema } from "./asset";
import { ClientEntrySchema } from "./client";
import { UserDetailsSchemaProperties, withValidation } from "./details";
import { getUserEntityResourceSchema } from "./entity";
import { VoucherEntrySchema } from "./voucher";

export * from "./asset";
export * from "./client";
export * from "./details";
export * from "./details.transforms";
export * from "./entity";
export * from "./voucher";

export enum UserResourceTypeEnum {
  ASSET = "asset",
  ENTITY = "entity",
}

export const UserResourceDetailsBaseSchema = z.object(
  UserDetailsSchemaProperties,
);

export const UserResourceDetailsSchema = UserResourceDetailsBaseSchema.extend({
  createdAt: z.date(),
  updatedAt: z.date(),
});

const isNode = () =>
  typeof process !== "undefined" &&
  !!process.versions &&
  !!process.versions.node;

export const UserResourceDetailsFormSchema =
  UserResourceDetailsBaseSchema.extend({
    userId: z.string().meta({ hidden: true }),
    identificationNumber:
      UserDetailsSchemaProperties.identificationNumber.transform((e) =>
        e.toUpperCase(),
      ),
    contactNumber: UserDetailsSchemaProperties.contactNumber
      .default("+65")
      .meta({ placeholder: "+65 1234 5678" })
      .transform((v) => {
        if (isNode()) return v;
        const parser = new AsYouType();
        let value = v;
        if (value && !value.startsWith("+")) {
          value = "+" + value;
        }
        return parser.input(value);
      }),
  });

export const UserResourceDetailsBaseSchemaWithValidation = withValidation(
  UserResourceDetailsBaseSchema,
);

export const UserResourceDetailsFormSchemaWithValidation = withValidation(
  UserResourceDetailsBaseSchema.extend({
    userId: z.string(),
  }),
);

export const UserResourceEntitySchema = getUserEntityResourceSchema({
  _id: z.string().min(1),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const UserResourceEntityCreateFormSchema = getUserEntityResourceSchema({
  userId: z.string().min(1),
});

export const UserResourceEntityFormSchema = getUserEntityResourceSchema({
  _id: z.string().min(1),
  userId: z.string().min(1),
});

export const UserResourceEntityUpdateFormSchema = getUserEntityResourceSchema({
  _id: z.string().min(1),
  userId: z.string().min(1),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const UserResourceEntitiesSchema = z.array(UserResourceEntitySchema);

export const UserResourceAssetSchema = getUserAssetResourceSchema({
  _id: z.string().min(1),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const UserResourceAssetCreateFormSchema = getUserAssetResourceSchema({
  userId: z.string().min(1),
});

export const UserResourceAssetFormSchema = getUserAssetResourceSchema({
  _id: z.string().min(1),
  userId: z.string().min(1),
});

export const UserResourceAssetUpdateFormSchema = getUserAssetResourceSchema({
  _id: z.string().min(1),
  userId: z.string().min(1),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const UserResourceAssetsSchema = z.array(UserResourceAssetSchema);

export const UserResourceVouchersSchema = z.array(VoucherEntrySchema);

export const UserResourceClientsSchema = z.array(ClientEntrySchema);

export const UserResourceDocumentSchema = z.object({
  _id: z.string().min(1),
  assets: UserResourceAssetsSchema,
  entities: UserResourceEntitiesSchema,
  details: UserResourceDetailsSchema,
  vouchers: z.undefined().or(UserResourceVouchersSchema),
  clients: z.undefined().or(UserResourceClientsSchema),
});

export type TUserResourceDocumentSchema = z.infer<
  typeof UserResourceDocumentSchema
>;
