import { z } from "../../xzod";

export const LPAFormSchema = z.object({
  calendarEventId: z.string().min(1, "Please select a calendar slot.").meta({
    displayName: "Appointment Slot",
  }),
  calendarId: z
    .enum(["alvin@guardianlaw.com.sg", "liane@guardianlaw.com.sg"])
    .meta({ hidden: true })
    .default("liane@guardianlaw.com.sg"),
});
