// @ts-nocheck

import countries from "country-list";

import {
  FINRegex,
  GENDER,
  IDENTIFICATION_DOCUMENT_TYPES_ENUM,
  NRICRegex,
} from "../constants";
import { z } from "../xzod";

/**
 * User Resource Schema
 */

export const UserCharityResource = z.object({
  charityName: z
    .string()
    .trim()
    .describe("Name of Charity, According to ACRA Records."),
  UEN: z
    .string()
    .trim()
    .describe("Unique Entity Number, According to ACRA Records.")
    .meta({ placeholder: "201819172D" }),
});

export const UserPersonResource = z.object({
  name: z
    .string()
    .min(1, "Please enter your name.")
    .trim()
    .describe("Full Name, Per Identification Document."),
  identificationDocument: z
    .nativeEnum(IDENTIFICATION_DOCUMENT_TYPES_ENUM)
    .default(IDENTIFICATION_DOCUMENT_TYPES_ENUM.NRIC),
  countryOfIssue: z
    .string()
    .default("sg")
    .describe("The country of issue of your Identification Document.")
    .meta({ choiceMap: countries.getCodeList() }),

  identificationNumber: z
    .string()
    .trim()
    .min(1, "Please enter a valid identification number.")
    .describe("Per your Identification Document.")
    .transform((v) => v.toUpperCase()),
  age: z.coerce.number().min(0).default(18),
  gender: z.nativeEnum(GENDER).default(GENDER.MALE),
  address: z
    .string()
    .trim()
    .min(1, "Please enter a valid address.")
    .describe("Please include your unit number where applicable."),
  unitNumber: z
    .string()
    .trim()
    .describe("Please include your unit number where applicable."),
  // relationship: z.enum([
  //   "Spouse",
  //   "Child",
  //   "Parent",
  //   "Sibling",
  //   "Grandparent",
  //   "Aunt/Uncle",
  //   "Niece/Nephew",
  //   "Cousin",
  //   "Friend",
  // ]),
  // contactNumber: z.string().trim(),
});

export const UserEntityResourceMap = {
  Charity: UserCharityResource.extend({
    _id: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  }),
  Person: UserPersonResource.extend({
    _id: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  }),
};

export const UserEntityTitleTransformsMap = {
  Charity: (data: z.infer<typeof UserEntityResourceMap.Charity>) =>
    `${data.charityName} (UEN No. ${data.UEN})`,
  Person: (data: z.infer<typeof UserEntityResourceMap.Person>) =>
    `${data.name} (${
      data.identificationDocument
    } No. ${data.identificationNumber.substring(
      data.identificationNumber.length - 4,
      data.identificationNumber.length,
    )})`,
};

export const UserEntityDefineTransformsMap = {
  Charity: (data: z.infer<typeof UserEntityResourceMap.Charity>) =>
    `${data.charityName} (UEN No. ${data.UEN})`,
  Person: (data: z.infer<typeof UserEntityResourceMap.Person>) =>
    `${data.name} (${data.identificationDocument} No. ${data.identificationNumber}) of ${[data.unitNumber, data.address].filter(Boolean).join(" ")}`,
};

export const UserEntityDefinitionTransformsMap = {
  Charity: (data: z.infer<typeof UserEntityResourceMap.Charity>) =>
    `${data.charityName} - ${data.UEN}`,
  Person: (data: z.infer<typeof UserEntityResourceMap.Person>) =>
    `${data.name} - ${data.identificationNumber}`,
};

export const MetaUserPersonResource = UserEntityResourceMap.Person.extend({
  _id: z.string(),
}).transform((data) => ({
  value: data._id,
  display: UserEntityTitleTransformsMap.Person(data),
}));

export const MetaUserCharityResource = UserEntityResourceMap.Charity.extend({
  _id: z.string(),
}).transform((data) => ({
  value: data._id,
  display: UserEntityTitleTransformsMap.Charity(data),
}));

export const MetaUserResource = z.union([
  MetaUserPersonResource,
  MetaUserCharityResource,
]);

export enum EntityTypeEnum {
  PERSON = "Person",
  CHARITY = "Charity",
}

export const getUserEntityResourceSchema = <T extends z.ZodRawShape>(
  BaseSchema: T,
) => {
  return z.discriminatedUnion("type", [
    UserCharityResource.extend(BaseSchema).extend({
      type: z.literal(EntityTypeEnum.CHARITY).meta({ hidden: true }),
    }),
    UserPersonResource.extend(BaseSchema).extend({
      type: z.literal(EntityTypeEnum.PERSON).meta({ hidden: true }),
    }),
  ]);
};

export const getValidatedUserEntityResourceSchema = <T extends z.ZodRawShape>(
  BaseSchema: T,
) => {
  return z
    .discriminatedUnion("type", [
      UserCharityResource.extend(BaseSchema).extend({
        type: z.literal(EntityTypeEnum.CHARITY).meta({ hidden: true }),
      }),
      UserPersonResource.extend(BaseSchema).extend({
        type: z.literal(EntityTypeEnum.PERSON).meta({ hidden: true }),
      }),
    ])
    .superRefine((val, ctx) => {
      if (val.type === "Person") {
        if (
          val.identificationDocument === IDENTIFICATION_DOCUMENT_TYPES_ENUM.NRIC
        ) {
          const result = NRICRegex.test(val.identificationNumber || "");
          if (!result) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please enter a valid NRIC number.",
              path: ["identificationNumber"],
            });
          }
        } else if (
          val.identificationDocument === IDENTIFICATION_DOCUMENT_TYPES_ENUM.FIN
        ) {
          const result = FINRegex.test(val.identificationNumber || "");
          if (!result) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please enter a valid FIN number.",
              path: ["identificationNumber"],
            });
          }
        } else if (
          val.identificationDocument === IDENTIFICATION_DOCUMENT_TYPES_ENUM.BCS
        ) {
          const result = NRICRegex.test(val.identificationNumber || "");
          if (!result) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please enter a valid FIN number.",
              path: ["identificationNumber"],
            });
          }
        }
      }
    });
};

export const UserEntityResourceSchema = getUserEntityResourceSchema({
  _id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const UserEntityResourceArraySchema = z.array(UserEntityResourceSchema);

export type TUserEntityResourceSchema = z.infer<
  typeof UserEntityResourceSchema
>;

export type TUserEntityResourceArraySchema = z.infer<
  typeof UserEntityResourceArraySchema
>;
