import { ASSET_TYPES_ENUM } from "../../user-resource";
import { MetaUserPersonResource } from "../../user-resource/entity";
import { z } from "../../xzod";
import {
  ALLOCATION_MODE_ENUM,
  AmountOnlyAllocationsSchema,
  PercentageOnlyAllocationsSchema,
} from "./common.schema";

export const GeneralAllocationsSchema = z.union([
  PercentageOnlyAllocationsSchema,
  AmountOnlyAllocationsSchema,
]);

const ArrAmountOnlyAllocationsSchema = z.array(AmountOnlyAllocationsSchema);
const ArrPercentageOnlyAllocationsSchema = z.array(
  PercentageOnlyAllocationsSchema,
);

export enum AssetAllocationTypeEnum {
  PERCENTAGE = "Percentage",
  AMOUNT = "Amount",
  WHOLE = "Whole",
}

export const AssetAllocationTypeAllowableDict = {
  [ASSET_TYPES_ENUM.BANK_ACCOUNT]: [
    AssetAllocationTypeEnum.PERCENTAGE,
    AssetAllocationTypeEnum.AMOUNT,
  ],
  [ASSET_TYPES_ENUM.COMPANY]: [
    AssetAllocationTypeEnum.PERCENTAGE,
    AssetAllocationTypeEnum.AMOUNT,
  ],
  [ASSET_TYPES_ENUM.INSURANCE]: [
    AssetAllocationTypeEnum.PERCENTAGE,
    AssetAllocationTypeEnum.AMOUNT,
  ],
  [ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT]: [
    AssetAllocationTypeEnum.PERCENTAGE,
    AssetAllocationTypeEnum.AMOUNT,
  ],
  [ASSET_TYPES_ENUM.REAL_ESTATE]: [
    AssetAllocationTypeEnum.PERCENTAGE,
    AssetAllocationTypeEnum.AMOUNT,
  ],
  [ASSET_TYPES_ENUM.PERSONAL_ITEMS]: [AssetAllocationTypeEnum.WHOLE],
};

export const DistributionSchema = z.discriminatedUnion("assetAllocationType", [
  z.object({
    allocations: z.array(PercentageOnlyAllocationsSchema),
    assetAllocationType: z.literal(AssetAllocationTypeEnum.PERCENTAGE).meta({
      hidden: true,
      disabled: true,
    }),
    asset: z.string().meta({ hidden: true }).nullable(),
  }),
  z.object({
    allocations: z.array(AmountOnlyAllocationsSchema),
    assetAllocationType: z.literal(AssetAllocationTypeEnum.AMOUNT).meta({
      hidden: true,
      disabled: true,
    }),
    asset: z.string().meta({ hidden: true }),
  }),
  z.object({
    beneficiary: z.string().meta({
      metaKey: "entity.Person",
      resourceSchema: MetaUserPersonResource,
      resourceAdd: {
        key: "entity",
        description: "Add a Person or Charity",
      },
    }),
    substituteBeneficiaries: z.array(
      z.string().meta({
        metaKey: "entity.Person",
        resourceSchema: MetaUserPersonResource,
        resourceAdd: {
          key: "entity",
          description: "Add a Person or Charity",
        },
      }),
    ),
    assetAllocationType: z.literal(AssetAllocationTypeEnum.WHOLE).meta({
      hidden: true,
      disabled: true,
    }),
    asset: z.string().meta({ hidden: true }),
  }),
]);

export enum WillLawerReviewChoices {
  EN = "Review and witness my will in English.",
  CN = "Review and witness my will in Chinese.",
  NO = "I want to use my own witnesses.",
}

export enum LawFirmChoices {
  RWW = "Robert Wang & Woo LLP",
  GUARDIANLAW = "Guardian Law",
  LEGALINK = "Legal Ink Law Corporation",
  NONE = "",
}

export enum BURIAL_INSTRUCTIONS_CHOICES {
  NONE = "I don't wish to state my burial instructions.",
  BURIAL = "I want to be buried in Singapore.",
  CREMATED_SCATTERED_GROUND = "I want to be cremated and my ashes scattered.",
  CREMATED_SCATTERED_SEA = "I want to be cremated and my ashes scattered at sea.",
  CREMATED_COLUMBARIUM = "I want to be cremated, and my ashes be kept at a crematorium.",
}

export enum CEMETERY_LOCATION_CHOICES {
  CCK_AHMADIYYA = "Chua Chu Kang Ahmadiyya Jama'at Cemetery",
  CCK_BAHAI = "Chua Chu Kang Bahai Cemetery",
  CCK_CHINESE = "Chua Chu Kang Chinese Cemetery",
  CCK_CHRISTIAN = "Chua Chu Kang Christian Cemetery",
  CCK_HINDU = "Chua Chu Kang Hindu Cemetery",
  CCK_JEWISH = "Chua Chu Kang Jewish Cemetery",
  CCK_MUSLIM = "Chua Chu Kang Muslim Cemetery",
  CCK_PARSI = "Chua Chu Kang Parsi Cemetery",
  LAWN = "Lawn Cemetery",
  KRANJI = "State Cemetery, Kranji",
  ANY = "Any Cemetery in Singapore",
}

export enum COLUMBARIUM_LOCATION_CHOICES {
  ANY = "Any Columbarium in Singapore",
  GARDEN_OR_REMEMBERANCE = "The Garden of Remembrance",
  NRIVANA_MEMORIAL = "Nirvana Memorial Garden Singapore",
  SEH_ONG = "Seh Ong Charity",
  CHOA_CHU_KANG_COLUMBARIUM = "Choa Chu Kang Columbarium",
  MANDAI_CREMATORIUM_AND_COLUMBARIUM_COMPLEX = "Mandai Columbarium",
  YISHUN_COLUMBARIUM = "Yishun Columbarium",
  GOLDHILL = "Goldhill Memorial Centre",
  TIAN_JUE = "Tian Jue Columbarium",
  LIN_SAN = "Lin San Temple",
  PECK_SAN_THENG = "Peck San Theng - Kwong Wai Siew",
  KONG_MENG_SAN_PHOR_KARK_SEE_MONASTERY = "Kong Meng San Phor Kark See Temple/Crematorium",
  TSE_TOH_AUM_TEMPLE = "Tse Tho Aum Temple",
  FOO_HAI_CHAN = "Foo Hai Ch'an Monastery",
  FO_GUAN_SHAN = "Fo Guang Shan (Singapore)",
  SAN_QING_GONG = "San Qing Gong 三清宫",
  WAN_F0_LIN = "Wan Fo Lin Temple",
  TAI_PEI_YUEN = "Tai Pei Yuen Temple",
  BUDDHIST_UNION = "The Buddhist Union",
  FONG_YUN_THAI = "Fong Yun Thai Khek Association",
  TONG_TECK_SIAN_TONG = "Tong Teck Sian Tong Temple",
  SEU_TECK_SEAN_TONG = "Seu Teck Sean Tong",
  PILU_TEMPLTE = "Pilu Temple",
  ALL_SAINTS = "All Saints Memorial Chapel",
  SAINT_MARY = "Saint Mary of The Angel Church",
  SPIRITUAL_GRACT = "Spiritual Grace Presbyterian Church",
  TRINITY_METHODIST = "Trinity Methodist Church",
  FAITH_METHODIST = "Faith Methodist Church",
  TELOK_AYER = "Telok Ayer Methodist Church",
  HOLY_FAMILY = "Holy Family Church",
  ST_ANTHOY = "Saint Anthony Church",
  ST_PETER = "Saint Peter and Paul Church",
  IMH = "Immaculate Heart of Mary Church",
  ST_IGNATIUS = "Saint Ignatius Church",
  ST_THERESA = "Saint Teresa Church",
  ST_MICHAEL = "Saint Michael Church",
}

export enum CREMATORIUM_LOCATION_CHOICES {
  BRIGHT_HILL_TEMPLE = "Kong Meng San Phor Kark See Monastery (Bright Hill Temple)",
  TZE_THO_AUM = "Tze Tho Aum Temple",
  CCK_CREMATORIUM = "Choa Chu Kang Crematorium",
  MANDAI_CREMATORIUM = "Mandai Crematorium",
  ANY = "Any Crematorium in Singapore",
}

export enum FUNERAL_INSTRUCTIONS_CHOICES {
  NO = "I do not want last rites to be performed.",
  BUDDHIST = "I want Buddhist rites to be performed.",
  CHRISTIAN = "I want Christian rites to be performed.",
  CATHOLIC = "I want Catholic rites to be performed.",
  TAOIST = "I want Taoist rites to be performed.",
  HINDU = "I want Hindu rites to be performed.",
  SIKH = "I want Sikh rites to be performed.",
  NON_RELIGIOUS = "I want an secular funeral.",
}

export enum SEA_SCATTERING_LOCATION_CHOICES {
  PULAU_SEMAKAU = "South of Pulau Semakau.",
  ANY = "Any authorised location in the sea around Singapore.",
}

export enum INLAND_SCATTERING_LOCATION_CHOICES {
  GARDEN_OF_PEACE = "Garden of Peace",
  GARDEN_OF_SERENITY = "Garden of Serenity",
}

export const BaseWillSchemaProperties = {
  distributions: z.array(DistributionSchema),
  executors: z
    .array(
      z.string().meta({
        metaKey: "entity.Person",
        resourceSchema: MetaUserPersonResource,
        resourceAdd: {
          key: "entity",
          description: "Add a Person",
        },
      }),
    )
    .meta({
      placeholder: "Select a maximum of 4 people.",
      callouts: [
        {
          title: "Important!",
          description: "string;",
          icon: "check",
        },
      ],
    }),
  substituteExecutors: z
    .array(
      z.string().meta({
        metaKey: "entity.Person",
        resourceSchema: MetaUserPersonResource,
        resourceAdd: {
          key: "entity",
          description: "Add a Person",
        },
      }),
    )
    .meta({
      showOn: z.object({
        executors: z.array(z.string()).min(1),
      }),
    }),
  testamentaryGuardians: z
    .array(
      z.string().meta({
        resourceSchema: MetaUserPersonResource,
        resourceAdd: {
          key: "entity",
          description: "Add a Person",
        },
      }),
    )
    .meta({
      placeholder: "Select a maximum of 2 people.",
    }),
  substituteTestamentaryGuardians: z
    .array(
      z.string().meta({
        resourceSchema: MetaUserPersonResource,
        resourceAdd: {
          key: "entity",
          description: "Add a Person",
        },
      }),
    )
    .meta({
      showOn: z.object({
        testamentaryGuardians: z.array(z.string()).min(1),
      }),
    }),
  lawyerReview: z
    .nativeEnum(WillLawerReviewChoices)
    .default(WillLawerReviewChoices.EN),
  calendarEventId: z
    .string()
    .meta({
      displayName: "Appointment Slot",
      showOn: z.object({
        lawyerReview: z.union([
          z.literal(WillLawerReviewChoices.EN),
          z.literal(WillLawerReviewChoices.CN),
        ]),
      }),
    })
    .optional(),
  firmChoice: z
    .nativeEnum(LawFirmChoices)
    .default(LawFirmChoices.GUARDIANLAW)
    .meta({
      hidden: true,
      disabled: true,
      showOn: z.object({
        lawyerReview: z.union([
          z.literal(WillLawerReviewChoices.EN),
          z.literal(WillLawerReviewChoices.CN),
        ]),
      }),
    })
    .optional(),
  witnesses: z
    .array(
      z.string().meta({
        metaKey: "entity.Person",
        resourceSchema: MetaUserPersonResource,
        resourceAdd: {
          key: "entity",
          description: "Add a Person",
        },
      }),
    )
    .meta({
      showOn: z.object({
        lawyerReview: z.literal(WillLawerReviewChoices.NO),
      }),
      placeholder: "Select a maximum of 2 people.",
    }),
  burialInstructions: z
    .nativeEnum(BURIAL_INSTRUCTIONS_CHOICES)
    .default(BURIAL_INSTRUCTIONS_CHOICES.NONE),
  cemeteryLocation: z
    .nativeEnum(CEMETERY_LOCATION_CHOICES)
    .meta({
      showOn: z.object({
        burialInstructions: z.literal(BURIAL_INSTRUCTIONS_CHOICES.BURIAL),
      }),
    })
    .default(CEMETERY_LOCATION_CHOICES.CCK_CHINESE),
  crematoriumLocation: z
    .nativeEnum(CREMATORIUM_LOCATION_CHOICES)
    .meta({
      showOn: z.object({
        burialInstructions: z.union([
          z.literal(BURIAL_INSTRUCTIONS_CHOICES.CREMATED_COLUMBARIUM),
          z.literal(BURIAL_INSTRUCTIONS_CHOICES.CREMATED_SCATTERED_GROUND),
          z.literal(BURIAL_INSTRUCTIONS_CHOICES.CREMATED_SCATTERED_SEA),
        ]),
      }),
    })
    .default(CREMATORIUM_LOCATION_CHOICES.CCK_CREMATORIUM),
  inlandScatteringLocation: z
    .nativeEnum(INLAND_SCATTERING_LOCATION_CHOICES)
    .meta({
      showOn: z.object({
        burialInstructions: z.literal(
          BURIAL_INSTRUCTIONS_CHOICES.CREMATED_SCATTERED_GROUND,
        ),
      }),
    })
    .default(INLAND_SCATTERING_LOCATION_CHOICES.GARDEN_OF_PEACE),
  seaScatteringLocation: z
    .nativeEnum(SEA_SCATTERING_LOCATION_CHOICES)
    .meta({
      showOn: z.object({
        burialInstructions: z.literal(
          BURIAL_INSTRUCTIONS_CHOICES.CREMATED_SCATTERED_SEA,
        ),
      }),
    })
    .default(SEA_SCATTERING_LOCATION_CHOICES.PULAU_SEMAKAU),
  columbariumLocation: z
    .nativeEnum(COLUMBARIUM_LOCATION_CHOICES)
    .meta({
      showOn: z.object({
        burialInstructions: z.literal(
          BURIAL_INSTRUCTIONS_CHOICES.CREMATED_COLUMBARIUM,
        ),
      }),
    })
    .default(COLUMBARIUM_LOCATION_CHOICES.GARDEN_OR_REMEMBERANCE),
  funeralInstructions: z
    .nativeEnum(FUNERAL_INSTRUCTIONS_CHOICES)
    .default(FUNERAL_INSTRUCTIONS_CHOICES.NO),
  funeralLocation: z.string().meta({
    placeholder: "#01-01, 1234 Street Name, Singapore 123456",
    description: "Please enter an address.",
    showOn: z.object({
      funeralInstructions: z.union([
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.BUDDHIST),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.CHRISTIAN),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.CATHOLIC),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.TAOIST),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.HINDU),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.SIKH),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.NON_RELIGIOUS),
      ]),
    }),
  }),
  funeralDuration: z.coerce.number().meta({
    stringSuffix: "Days",
    showOn: z.object({
      funeralInstructions: z.union([
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.BUDDHIST),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.CHRISTIAN),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.CATHOLIC),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.TAOIST),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.HINDU),
        z.literal(FUNERAL_INSTRUCTIONS_CHOICES.NON_RELIGIOUS),
      ]),
    }),
  }),
};

export const getWillResourceSchema = <T extends z.ZodRawShape>(
  BaseSchemaObj: T,
) => {
  return z.object(BaseSchemaObj).extend({
    ...BaseWillSchemaProperties,
    substituteExecutors:
      BaseWillSchemaProperties.substituteExecutors.optional(),
    substituteTestamentaryGuardians:
      BaseWillSchemaProperties.substituteTestamentaryGuardians.optional(),
    witnesses: BaseWillSchemaProperties.witnesses.optional(),
    cemeteryLocation: BaseWillSchemaProperties.cemeteryLocation.optional(),
    crematoriumLocation:
      BaseWillSchemaProperties.crematoriumLocation.optional(),
    inlandScatteringLocation:
      BaseWillSchemaProperties.inlandScatteringLocation.optional(),
    seaScatteringLocation:
      BaseWillSchemaProperties.seaScatteringLocation.optional(),
    columbariumLocation:
      BaseWillSchemaProperties.columbariumLocation.optional(),
    funeralLocation: BaseWillSchemaProperties.funeralLocation.optional(),
    funeralDuration: BaseWillSchemaProperties.funeralDuration.optional(),
  });
};

export const WillFormSchema = z.object(BaseWillSchemaProperties);

export const BaseValidatedWillFormSchema = z.object({
  ...BaseWillSchemaProperties,
  executors: BaseWillSchemaProperties.executors
    .min(1, "Please appoint at least one Executor.")
    .max(4, "Please appoint at most 4 Executors."),
  witnesses: BaseWillSchemaProperties.witnesses
    .min(2, "Please appoint two witnesses")
    .optional(),
  substituteExecutors: BaseWillSchemaProperties.substituteExecutors
    .min(0)
    .optional(),
  testamentaryGuardians: BaseWillSchemaProperties.testamentaryGuardians.max(
    2,
    "Please appoint at most 2 Testamentary Guardians.",
  ),
  substituteTestamentaryGuardians:
    BaseWillSchemaProperties.substituteTestamentaryGuardians.min(0).optional(),
  cemeteryLocation: BaseWillSchemaProperties.cemeteryLocation.optional(),
  crematoriumLocation: BaseWillSchemaProperties.crematoriumLocation.optional(),
  inlandScatteringLocation:
    BaseWillSchemaProperties.inlandScatteringLocation.optional(),
  seaScatteringLocation:
    BaseWillSchemaProperties.seaScatteringLocation.optional(),
  columbariumLocation: BaseWillSchemaProperties.columbariumLocation.optional(),
  funeralLocation: BaseWillSchemaProperties.funeralLocation.min(1).optional(),
  funeralDuration: BaseWillSchemaProperties.funeralDuration
    .min(1, "Please enter a valid number of days.")
    .default(1)
    .optional(),
});

export const WillFormValidator = (
  val: z.infer<typeof BaseValidatedWillFormSchema>,
  ctx: z.RefinementCtx,
) => {
  const validateTotalPercentage = (
    allocations: z.infer<typeof ArrPercentageOnlyAllocationsSchema>,
    trace: string[] = [],
  ) => {
    const totalPercentage = allocations.reduce(
      (acc, cur) => acc + cur.allocation,
      0,
    );
    if (totalPercentage !== 100) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Total percentage must be 100.",
        path: trace,
      });
    }
    allocations.forEach((allocation, idx) => {
      if (
        allocation.substituteAllocationMode ===
        ALLOCATION_MODE_ENUM.CUSTOM_PERCENT
      ) {
        validateTotalPercentage(allocation.substituteAllocations, [
          ...trace,
          `${idx}`,
          "substituteAllocations",
        ]);
      }
    });
  };

  const validateTotalAmount = (
    allocations: z.infer<typeof ArrAmountOnlyAllocationsSchema>,
    trace: string[] = [],
    previousAmount: number | undefined = undefined,
  ) => {
    const totalAmount = allocations.reduce(
      (acc, cur) => acc + cur.allocation,
      0,
    );
    if (previousAmount !== undefined && previousAmount < totalAmount) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Total percentage must be 100.",
        path: trace,
      });
    }
    allocations.forEach((allocation, idx) => {
      if (
        allocation.substituteAllocationMode ===
        ALLOCATION_MODE_ENUM.CUSTOM_AMOUNT
      ) {
        validateTotalAmount(
          allocation.substituteAllocations,
          [...trace, `${idx}`, "substituteAllocations"],
          allocation.allocation,
        );
      }
    });
  };

  if (val.distributions) {
    val.distributions.forEach(
      (distribution: z.infer<typeof DistributionSchema>, idx: number) => {
        if (distribution.assetAllocationType === "Percentage") {
          validateTotalPercentage(distribution.allocations, [
            "distributions",
            `${idx}`,
            "allocations",
          ]);
        } else if (distribution.assetAllocationType === "Amount") {
          validateTotalAmount(distribution.allocations, [
            "distributions",
            `${idx}`,
            "allocations",
          ]);
        }
      },
    );
  }

  if (val.lawyerReview === WillLawerReviewChoices.NO) {
    if (!val.witnesses) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter a witness.",
        path: ["witnesses"],
      });
    } else {
      val.witnesses?.length < 2 &&
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 2,
          type: "array",
          inclusive: true,
          message: "Please select at least 2 witnesses.",
          path: ["witnesses"],
        });

      val.witnesses?.length > 2 &&
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 2,
          type: "array",
          inclusive: true,
          message: "Please select at most 2 witnesses.",
          path: ["witnesses"],
        });
    }
  } else {
    if (!val.calendarEventId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please select a calendar slot.",
        path: ["calendarEventId"],
      });
    }
  }

  if (
    val.funeralInstructions !== FUNERAL_INSTRUCTIONS_CHOICES.NO &&
    !val.funeralLocation
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Please enter a funeral location.",
      path: ["funeralLocation"],
    });
  }
};

export const ValidatedWillFormSchema =
  BaseValidatedWillFormSchema.superRefine(WillFormValidator);
