export const userNavigation = [
  {
    name: "Profile",
    href: (userId: string) => `/${userId}/personal-details/profile/`,
    current: true,
    icon: "person",
  },
  {
    name: "Billing",
    href: (userId: string) => `/${userId}/personal-details/billing/`,
    current: true,
    icon: "credit_card",
  },
  {
    name: "Security",
    href: (userId: string) => `/${userId}/personal-details/set-password/`,
    current: true,
    icon: "key",
  },
  {
    name: "Notifications",
    href: (userId: string) => `/${userId}/personal-details/notifications/`,
    current: true,
    icon: "notifications",
  },
];

export const adminNavigation = [
  { name: "Users", href: "/admin/users", current: false, icon: "book" },
];
