import { MetaUserPersonResource } from "../../user-resource/entity";
import { z } from "../../xzod";

export enum DoneePowerChoices {
  BOTH = "Personal Welfare & Property and Affairs",
  PERSONAL_WELFARE = "Personal Welfare Only",
  PROPERTY_AND_AFFAIRS = "Property and Affairs Only",
}

export enum ReplacementDoneeChoices {
  ANY = "Any",
  NAMED = "Selected Donee",
  PERSONAL_WELFARE = "Any Personal Welfare Donee",
  PROPERTY_AND_AFFAIRS = "Any Property and Affairs Donee",
}

export enum PartReplacementDoneeChoices {
  ANY = "Any",
  PERSONAL_WELFARE = "Any Personal Welfare Donee",
  PROPERTY_AND_AFFAIRS = "Any Property and Affairs Donee",
}

export enum DoneePowerJointlySeverallyChoices {
  JOINTLY = "Jointly",
  JOINTLY_AND_SEVERALLY = "Jointly and Severally",
}

export enum DoneePowerYesNoChoices {
  YES = "Yes",
  NO = "No",
}

export enum DoneePowerCashRestrictionChoices {
  NONE = "Unrestricted",
  NOT_ALLOWED = "Not Allowed",
  RESTRICTED = "Restricted",
}

export const doneeProperties = {
  donee: z.string().meta({
    metaKey: "entity.Person",
    resourceSchema: MetaUserPersonResource,
    resourceAdd: {
      key: "entity",
      description: "Add a Person",
    },
  }),
  powers: z.nativeEnum(DoneePowerChoices).default(DoneePowerChoices.BOTH),
};

export const replacementDoneeProperties = {
  replacementType: z
    .nativeEnum(ReplacementDoneeChoices)
    .default(ReplacementDoneeChoices.ANY),
  donee: z.string().meta({
    metaKey: "entity.Person",
    resourceSchema: MetaUserPersonResource,
    resourceAdd: {
      key: "entity",
      description: "Add a Person",
    },
  }),
};

const baseSchemaProperties = {
  donees: z.array(z.object(doneeProperties)),
  replacementDonees: z.array(
    z.discriminatedUnion("replacementType", [
      z.object(replacementDoneeProperties).extend({
        replacementType: z.literal(ReplacementDoneeChoices.NAMED),
        primaryDonee: z.string().meta({
          metaKey: "entity.Person",
          resourceSchema: MetaUserPersonResource,
          resourceAdd: {
            key: "entity",
            description: "Add a Person",
          },
        }),
      }),
      z.object(replacementDoneeProperties).extend({
        replacementType: z.literal(ReplacementDoneeChoices.ANY),
      }),
      z.object(replacementDoneeProperties).extend({
        replacementType: z.literal(ReplacementDoneeChoices.PERSONAL_WELFARE),
      }),
      z.object(replacementDoneeProperties).extend({
        replacementType: z.literal(
          ReplacementDoneeChoices.PROPERTY_AND_AFFAIRS,
        ),
      }),
    ]),
  ),
  // cannot be null if there is more than one donee with this power
  methodOfExerciseOfPersonalWelfarePowers: z
    .nativeEnum(DoneePowerJointlySeverallyChoices)
    .nullable(),
  powerToRefuseMedialTreatment: z
    .nativeEnum(DoneePowerYesNoChoices)
    .default(DoneePowerYesNoChoices.NO),
  // cannot be null if there is more than one donee with this power
  methodOfExerciseOfPropertyAndAffairsPowers: z
    .nativeEnum(DoneePowerJointlySeverallyChoices)
    .nullable(),
  powerToGiveCash: z
    .nativeEnum(DoneePowerCashRestrictionChoices)
    .default(DoneePowerCashRestrictionChoices.NONE),
  cashRestrictionAmount: z.number().nullable().optional(),
  powerToSellProperty: z
    .nativeEnum(DoneePowerYesNoChoices)
    .default(DoneePowerYesNoChoices.NO),
  restrictedProperty: z.string().nullable().optional(),
};

export const LegacyLPAFormSchema = z.object(baseSchemaProperties);

export const getLpaResourceSchema = <T extends z.ZodRawShape>(
  BaseSchemaObj: T,
) => {
  return z.object(BaseSchemaObj).extend({
    ...baseSchemaProperties,
  });
};
