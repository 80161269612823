// @ts-nocheck

import countries from "country-list";

import { REAL_ESTATE_TYPES_ENUM } from "../constants";
import { z } from "../xzod";

export enum ASSET_TYPES_ENUM {
  BANK_ACCOUNT = "BankAccount",
  COMPANY = "Company",
  INSURANCE = "Insurance",
  INVESTMENT_ACCOUNT = "InvestmentAccount",
  PERSONAL_ITEMS = "PersonalItems",
  REAL_ESTATE = "RealEstate",
}

export enum HOLDING_TYPE_ENUM {
  SOLELY = "In My Own Name",
  JOINTLY = "Jointly with Another",
}

export enum REAL_ESTATE_HOLDING_TYPE_ENUM {
  SOLE = "Sole Owner",
  JOINT = "Joint-Tenant",
  TIC = "Tenant-in-Common",
}

export enum MORTGAGE_ENUM {
  PAID_UP = "Fully Paid Up",
  MORTGAGE = "Still Under Mortgage",
}

/**
 * User Resource Schema
 */

export const UserAssetBankAccountResource = z.object({
  bankName: z.string().min(1, "Please enter a valid bank name.").trim(),
  accountNumber: z
    .string()
    .min(1, "Please enter a valid account number.")
    .trim(),
  holdingType: z
    .nativeEnum(HOLDING_TYPE_ENUM)
    .default(HOLDING_TYPE_ENUM.SOLELY),
});

export const UserAssetCompanyResource = z.object({
  companyName: z.string().min(1, "Please enter a valid company name.").trim(),
  registrationNumber: z
    .string()
    .min(1, "Please enter a valid registration number.")
    .trim(),
  countryOfIncorporation: z
    .string()
    .default("sg")
    .meta({ choiceMap: countries.getCodeList() }),
  numberOfSharesHeld: z.coerce
    .number()
    .min(1, "Please enter a valid number of shares."),
  percentageOfSharesHeld: z.coerce
    .number()
    .min(0, "Please enter a valid percentage.")
    .max(100, "You cannot own more than 100% of a company."),
});

export const UserAssetInsuranceResource = z.object({
  nameOfInsurer: z.string().min(1, "Please enter a valid insurer.").trim(),
  nameOfInsurancePlan: z
    .string()
    .min(1, "Please enter a valid insurance plan.")
    .trim(),
  insurancePolicyNumber: z
    .string()
    .min(1, "Please enter a valid policy number.")
    .trim(),
  hasExistingNomination: z.boolean().default(false),
});

export const UserAssetInvestmentAccountResource = z.object({
  nameOfFinancialInstitution: z
    .string()
    .min(1, "Please enter a valid financial instituition name.")
    .trim(),
  investmentAccountNumber: z
    .string()
    .min(1, "Please enter a valid account number.")
    .trim(),
  holdingType: z
    .nativeEnum(HOLDING_TYPE_ENUM)
    .default(HOLDING_TYPE_ENUM.SOLELY),
});

export const UserAssetPersonalItemsResource = z.object({
  shortNameOfItem: z
    .string()
    .min(1, "Please enter a short name descriptive of your item.")
    .trim(),
  descriptionOfItem: z
    .string()
    .min(1, "Please enter a suitable description of your item.")
    .trim(),
  images: z
    .array(z.string().url())
    .min(1, "Please submit at least 1 image of your item."),
});

export const UserAssetRealEstateResource = z.object({
  address: z.string().min(1, "Please enter a valid asset address.").trim(),
  unitNumber: z
    .string()
    .trim()
    .describe("Please include your unit number where applicable.")
    .optional(),
  country: z
    .string()
    .meta({ choiceMap: countries.getCodeList() })
    .default("sg"),
  realEstateType: z
    .nativeEnum(REAL_ESTATE_TYPES_ENUM)
    .default(REAL_ESTATE_TYPES_ENUM.HDB_FLAT),
  hasOutstandingMortgage: z
    .nativeEnum(MORTGAGE_ENUM)
    .default(MORTGAGE_ENUM.MORTGAGE),
  holdingType: z
    .nativeEnum(REAL_ESTATE_HOLDING_TYPE_ENUM)
    .default(REAL_ESTATE_HOLDING_TYPE_ENUM.JOINT),
});

export const UserAssetResourceMap = {
  [ASSET_TYPES_ENUM.BANK_ACCOUNT]: UserAssetBankAccountResource.extend({
    _id: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  }),
  [ASSET_TYPES_ENUM.COMPANY]: UserAssetCompanyResource.extend({
    _id: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  }),
  [ASSET_TYPES_ENUM.INSURANCE]: UserAssetInsuranceResource.extend({
    _id: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  }),
  [ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT]:
    UserAssetInvestmentAccountResource.extend({
      _id: z.string(),
      createdAt: z.date(),
      updatedAt: z.date(),
    }),
  [ASSET_TYPES_ENUM.PERSONAL_ITEMS]: UserAssetPersonalItemsResource.extend({
    _id: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  }),
  [ASSET_TYPES_ENUM.REAL_ESTATE]: UserAssetRealEstateResource.extend({
    _id: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  }),
};

export const UserAssetTitleTransformsMap = {
  [ASSET_TYPES_ENUM.BANK_ACCOUNT]: (
    data: z.infer<typeof UserAssetBankAccountResource>,
  ) =>
    `${data.bankName} - ${data.accountNumber.substring(
      data.accountNumber.length - 4,
      data.accountNumber.length,
    )}`,
  [ASSET_TYPES_ENUM.COMPANY]: (
    data: z.infer<typeof UserAssetCompanyResource>,
  ) => `${data.companyName} - ${data.registrationNumber}`,
  [ASSET_TYPES_ENUM.INSURANCE]: (
    data: z.infer<typeof UserAssetInsuranceResource>,
  ) =>
    `${data.nameOfInsurer} - ${
      data.nameOfInsurancePlan
    } - ${data.insurancePolicyNumber.substring(
      data.insurancePolicyNumber.length - 4,
      data.insurancePolicyNumber.length,
    )}`,
  [ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT]: (
    data: z.infer<typeof UserAssetInvestmentAccountResource>,
  ) =>
    `${
      data.nameOfFinancialInstitution
    } - ${data.investmentAccountNumber.substring(
      data.investmentAccountNumber.length - 4,
      data.investmentAccountNumber.length,
    )}`,
  [ASSET_TYPES_ENUM.PERSONAL_ITEMS]: (
    data: z.infer<typeof UserAssetPersonalItemsResource>,
  ) => "",
  [ASSET_TYPES_ENUM.REAL_ESTATE]: (
    data: z.infer<typeof UserAssetRealEstateResource>,
  ) =>
    data.unitNumber
      ? `${data.address} (${data.realEstateType})`
      : `${data.unitNumber} ${data.address} (${data.realEstateType})`,
};

export const UserAssetDefineTransformsMap = {
  [ASSET_TYPES_ENUM.BANK_ACCOUNT]: (
    data: z.infer<typeof UserAssetBankAccountResource>,
  ) => `Bank Account at ${data.bankName} (Account No. ${data.accountNumber})`,
  [ASSET_TYPES_ENUM.COMPANY]: (
    data: z.infer<typeof UserAssetCompanyResource>,
  ) =>
    `${data.companyName} (${
      data.countryOfIncorporation === "SG" ? "UEN" : "Registration"
    } No. ${data.registrationNumber})`,
  [ASSET_TYPES_ENUM.INSURANCE]: (
    data: z.infer<typeof UserAssetInsuranceResource>,
  ) =>
    `Insurance Policy with ${data.nameOfInsurer} (${data.nameOfInsurancePlan} with Policy No. ${data.insurancePolicyNumber})`,
  [ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT]: (
    data: z.infer<typeof UserAssetInvestmentAccountResource>,
  ) =>
    `Investment Account at ${data.nameOfFinancialInstitution} (Account No. ${data.investmentAccountNumber})`,
  [ASSET_TYPES_ENUM.PERSONAL_ITEMS]: (
    data: z.infer<typeof UserAssetPersonalItemsResource>,
  ) => "",
  [ASSET_TYPES_ENUM.REAL_ESTATE]: (
    data: z.infer<typeof UserAssetRealEstateResource>,
  ) =>
    data.unitNumber
      ? `${data.realEstateType} at ${data.unitNumber} ${data.address}`
      : `${data.realEstateType} at ${data.address}`,
};

export const UserAssetDefinitionTransformsMap = {
  [ASSET_TYPES_ENUM.BANK_ACCOUNT]: (
    data: z.infer<typeof UserAssetBankAccountResource>,
  ) => `${data.bankName} - ${data.accountNumber}`,
  [ASSET_TYPES_ENUM.COMPANY]: (
    data: z.infer<typeof UserAssetCompanyResource>,
  ) => `${data.companyName} - ${data.registrationNumber}`,
  [ASSET_TYPES_ENUM.INSURANCE]: (
    data: z.infer<typeof UserAssetInsuranceResource>,
  ) => `${data.nameOfInsurer} - ${data.insurancePolicyNumber}`,
  [ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT]: (
    data: z.infer<typeof UserAssetInvestmentAccountResource>,
  ) => `${data.nameOfFinancialInstitution} - ${data.investmentAccountNumber}`,
  [ASSET_TYPES_ENUM.PERSONAL_ITEMS]: (
    data: z.infer<typeof UserAssetPersonalItemsResource>,
  ) => "",
  [ASSET_TYPES_ENUM.REAL_ESTATE]: (
    data: z.infer<typeof UserAssetRealEstateResource>,
  ) =>
    data.unitNumber ? `${data.unitNumber} ${data.address}` : `${data.address}`,
};

export const getUserAssetResourceSchema = <T extends z.ZodRawShape>(
  BaseSchema: T,
) => {
  return z.discriminatedUnion("type", [
    UserAssetBankAccountResource.extend(BaseSchema).extend({
      type: z.literal(ASSET_TYPES_ENUM.BANK_ACCOUNT),
    }),
    UserAssetCompanyResource.extend(BaseSchema).extend({
      type: z.literal(ASSET_TYPES_ENUM.COMPANY),
    }),
    UserAssetInsuranceResource.extend(BaseSchema).extend({
      type: z.literal(ASSET_TYPES_ENUM.INSURANCE),
    }),
    UserAssetInvestmentAccountResource.extend(BaseSchema).extend({
      type: z.literal(ASSET_TYPES_ENUM.INVESTMENT_ACCOUNT),
    }),
    UserAssetPersonalItemsResource.extend(BaseSchema).extend({
      type: z.literal(ASSET_TYPES_ENUM.PERSONAL_ITEMS),
    }),
    UserAssetRealEstateResource.extend(BaseSchema).extend({
      type: z.literal(ASSET_TYPES_ENUM.REAL_ESTATE),
    }),
  ]);
};

export const UserAssetResourceSchema = getUserAssetResourceSchema({
  _id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const UserAssetResourceArraySchema = z.array(UserAssetResourceSchema);

export type TUserAssetResourceSchema = z.infer<typeof UserAssetResourceSchema>;

export type TUserAssetResourceArraySchema = z.infer<
  typeof UserAssetResourceArraySchema
>;
