import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "tailwind/utils";

export function ZSelectIndicator({
  selected,
  active,
}: {
  selected: boolean;
  active: boolean;
}) {
  if (!selected) return null;
  return (
    <span
      className={cn(
        "absolute right-2 flex h-3.5 w-3.5 items-center justify-center",
      )}
    >
      <CheckIcon className="h-4 w-4" />
    </span>
  );
}

export function ZSelectOption({
  value,
  display,
  disabled,
}: {
  value: string;
  display: string;
  disabled?: boolean;
}) {
  return (
    <Listbox.Option
      disabled={disabled}
      key={value}
      className={({ active }) =>
        cn(
          active && "bg-accent text-accent-foreground",
          "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        )
      }
      value={value}
    >
      {({ selected, active }) => (
        <>
          <span className={cn("block truncate")}>{display || "Unknown"}</span>
          <ZSelectIndicator active={active} selected={selected} />
        </>
      )}
    </Listbox.Option>
  );
}

export const ZSelect = ({
  onChange,
  value,
  multiple,
  placeholder,
  children,
  getDisplayValue,
  className,
}:
  | {
      onChange: (arg: string[]) => void;
      value: string[];
      multiple: true;
      placeholder?: string;
      children: React.ReactNode | React.ReactNode[] | undefined | false;
      getDisplayValue: () => string;
      className: string;
    }
  | {
      onChange: (arg: string) => void;
      value: string;
      multiple: false;
      placeholder?: string;
      children: React.ReactNode | React.ReactNode[] | undefined | false;
      getDisplayValue: () => string;
      className: string;
    }) => {
  const hasDisplayValue = multiple ? !!value?.length : !!value;

  return (
    <Listbox onChange={onChange} value={value} multiple={multiple}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            className={cn(
              "flex h-9 w-full items-center justify-between rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
              className,
            )}
          >
            <span
              className={cn(
                "block truncate",
                !hasDisplayValue && !placeholder && "text-transparent",
              )}
            >
              {getDisplayValue()}
            </span>
            <CaretSortIcon className="h-4 w-4 opacity-50" />
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full min-w-[6rem] overflow-auto rounded-md border bg-background p-1 text-base text-popover-foreground shadow-md focus:outline-none sm:text-sm">
              {children}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
