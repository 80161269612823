export enum RESOURCE_TYPE {
  ORDER_DATA = "ORDER_DATA",
  DOCUMENT = "DOCUMENT",
  INVOICE = "INVOICE",
}

export enum OrderStatusEnum {
  DRAFT = "Draft",
  AWAITING_APPOINTMENT = "Awaiting Lawyer Review",
  COMPLETED = "Completed",
  COMPLETED_AND_EXPIRED = "Completed and Expired",
}

export enum CompleteOrderStatusEnum {
  AWAITING_APPOINTMENT = OrderStatusEnum.AWAITING_APPOINTMENT,
  COMPLETED = OrderStatusEnum.COMPLETED,
  COMPLETED_AND_EXPIRED = OrderStatusEnum.COMPLETED_AND_EXPIRED,
}

export enum ORDER_TYPE {
  WILL = "WILL",
  LPA = "LPA",
  LEGACY_WILL = "LEGACY_WILL",
  LEGACY_LPA = "LEGACY_LPA",
}

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
} as const;

export enum IDENTIFICATION_DOCUMENT_TYPES_ENUM {
  NRIC = "NRIC",
  FIN = "FIN",
  Passport = "Passport",
  BCS = "Singapore Singapore Birth",
  BC = "Birth Certificate",
}

export enum REAL_ESTATE_TYPES_ENUM {
  HDB_FLAT = "HDB Flat",
  HDB_EC = "HDB Executive Condominium",
  PRIVATE_CONDOMINUM = "Private Condominium",
  CLUSTER_HOUSE = "Cluser House",
  TERRACE = "Terrace",
  SEMI_DETACHED = "Semi Detached",
  BUNGALOW = "Bungalow",
}

export const MARTIAL_STATUS = {
  SINGLE: "Single",
  MARRIED_CHILDREN: "Married with Children",
  MARRIED_UNDERAGED_CHILDREN: "Married with Underaged Children",
  MARRIED_NO_CHILDREN: "Married with No Children",
  DIVORCED_CHILDREN: "Divorced with Children",
  DIVORCED_UNDERAGED_CHILDREN: "Divorced with Underaged Children",
  DIVORCED_NO_CHILDREN: "Divorced with No Children",
  WIDOWED_CHILDREN: "Widowed with Children",
  WIDOWED_UNDERAGED_CHILDREN: "Widowed with Underaged Children",
  WIDOWED_NO_CHILDREN: "Widowed with No Children",
} as const;

export const NRICRegex = new RegExp("^[ST]\\d{7}[A-Z]$");
export const FINRegex = new RegExp("^[FGM]\\d{7}[A-Z]$");

export const INSTRUCTIONS = {
  NONE: "I don't wish to state my burial instructions.",
  BURIAL: "I want to be buried in Singapore.",
  CREMATED_SCATTERED_GROUND: "I want to be cremated and my ashes scattered.",
  CREMATED_SCATTERED_SEA:
    "I want to be cremated and my ashes scattered at sea.",
  CREMATED_COLUMBARIUM:
    "I want to be cremated, and my ashes be kept at a crematorium.",
};

export const RELIGION_TYPES = {
  BUDDHIST: "Buddhist",
  CHRISTIAN: "Christian",
  CATHOLIC: "Catholic",
  TAOIST: "Taoist",
  HINDU: "Hindu",
  NO_RELIGION: "Non Religious",
};

export const COUPON_TYPES = {
  PAID: "PAID",
  REFERRAL: "REFERRAL",
};
