exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-users-index-tsx": () => import("./../../../src/pages/admin/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-index-tsx" */),
  "component---src-pages-admin-users-user-id-order-id-document-tsx": () => import("./../../../src/pages/admin/users/[userId]/[orderId]/document.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-id-order-id-document-tsx" */),
  "component---src-pages-admin-users-user-id-order-id-index-tsx": () => import("./../../../src/pages/admin/users/[userId]/[orderId]/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-id-order-id-index-tsx" */),
  "component---src-pages-admin-users-user-id-personal-details-tsx": () => import("./../../../src/pages/admin/users/[userId]/personal-details.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-id-personal-details-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-server-redirects-notifications-change-success-tsx": () => import("./../../../src/pages/server-redirects/notifications-change-success.tsx" /* webpackChunkName: "component---src-pages-server-redirects-notifications-change-success-tsx" */),
  "component---src-pages-user-id-order-id-document-tsx": () => import("./../../../src/pages/[userId]/[orderId]/document.tsx" /* webpackChunkName: "component---src-pages-user-id-order-id-document-tsx" */),
  "component---src-pages-user-id-order-id-index-tsx": () => import("./../../../src/pages/[userId]/[orderId]/index.tsx" /* webpackChunkName: "component---src-pages-user-id-order-id-index-tsx" */),
  "component---src-pages-user-id-order-id-session-id-tsx": () => import("./../../../src/pages/[userId]/[orderId]/[sessionId].tsx" /* webpackChunkName: "component---src-pages-user-id-order-id-session-id-tsx" */),
  "component---src-pages-user-id-personal-details-billing-tsx": () => import("./../../../src/pages/[userId]/personal-details/billing.tsx" /* webpackChunkName: "component---src-pages-user-id-personal-details-billing-tsx" */),
  "component---src-pages-user-id-personal-details-notifications-tsx": () => import("./../../../src/pages/[userId]/personal-details/notifications.tsx" /* webpackChunkName: "component---src-pages-user-id-personal-details-notifications-tsx" */),
  "component---src-pages-user-id-personal-details-profile-tsx": () => import("./../../../src/pages/[userId]/personal-details/profile.tsx" /* webpackChunkName: "component---src-pages-user-id-personal-details-profile-tsx" */),
  "component---src-pages-user-id-personal-details-set-password-tsx": () => import("./../../../src/pages/[userId]/personal-details/set-password.tsx" /* webpackChunkName: "component---src-pages-user-id-personal-details-set-password-tsx" */)
}

