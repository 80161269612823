/*
This code is heavily inspired by https://github.com/anatine/zod-plugins/blob/main/packages/zod-meta/src/lib/zod-extensions.ts
 */

import { z, ZodTypeAny } from "zod";

type TChoice = {
  [key: string]: string;
};

type CallOutProps = {
  title: string;
  description: string;
  icon: string;
};

declare module "zod" {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface ZodSchema<
    Output = any,
    Def extends ZodTypeDef = ZodTypeDef,
    Input = Output,
  > {
    meta<T extends ZodSchema<Output, Def, Input>>(
      this: T,
      metadata: Partial<ZodTypeDef>,
    ): T;
  }

  export interface ZodTypeDef {
    placeholder?: string;
    description?: string;
    displayName?: string;
    hidden?: boolean;
    disabled?: boolean;
    choiceMap?: TChoice;
    stringSuffix?: string;
    iconSuffix?: string;
    iconPrefix?: string;
    stringPrefix?: string;
    metaKey?: string;
    resourceSchema?: z.ZodType<any, any, any>;
    resourceAdd?: {
      key: string;
      description: string;
    };
    showOn?: z.ZodType<any, any, any>;
    callouts?: CallOutProps | CallOutProps[];
  }
}

export interface FieldMetaAny extends ZodTypeAny {
  fieldMeta?: z.ZodTypeDef;
}

export function extendApi<T extends FieldMetaAny>(
  schema: T,
  FieldMeta: z.ZodTypeDef = {},
): T {
  Object.assign(schema._def || {}, FieldMeta);
  return schema;
}

export function extendZod(zod: typeof z, forceOverride = false) {
  if (!forceOverride && typeof zod.ZodSchema.prototype.meta !== "undefined") {
    // This zod instance is already extended with the required methods,
    // doing it again will just result in multiple wrapper methods for
    // `optional` and `nullable`
    return;
  }

  zod.ZodSchema.prototype.meta = function (metadata?: Partial<z.ZodTypeDef>) {
    return extendApi(this, metadata);
  };
}

extendZod(z);

export default z;
export { z };
