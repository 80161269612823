import { COUPON_TYPES } from "../constants";
import { z } from "../xzod";

export const VoucherEntrySchema = z.object({
  couponId: z.string(),
  promotionCodeId: z.string(),
  code: z.string(),
  amount: z.number(),
  dateRequested: z.date(),
  redeemed: z.boolean().optional(),
  type: z.nativeEnum(COUPON_TYPES),
});
