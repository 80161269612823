import React from "react";
import { useSession } from "@/hooks/useSession";
import { Link } from "gatsby";
import { ROLES, TRole } from "schema";
import { Button } from "ui/components/button";
import { SmallLoader } from "ui/components/small-loader";

export const NoAuthDisplay: React.FC<{
  title?: string;
  description?: string;
}> = ({
  description = "Sorry, we couldn’t find the page you’re looking for.",
  title = "Unauthorised",
}) => {
  const url = new URL(process.env.GATSBY_SERVER_URL || "");

  return (
    <div className="grid h-full w-full grow place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-accent-foreground">401</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-5xl">
          {title}
        </h1>
        <p className="mt-2 text-base leading-7 text-muted-foreground">
          {description}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button asChild>
            <Link to="/">Go back home</Link>
          </Button>
          <Button asChild variant="ghost">
            <a href={`${url.protocol}//${url.host}/auth/logout/`}>
              Authenticate <span aria-hidden="true">&rarr;</span>
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export const UnAuthorisedPane: React.FC<{
  children: React.ReactNode;
  permissions: TRole[];
  userId?: string;
}> = ({ children, permissions, userId }) => {
  const { isLoading, isLoggedIn, data } = useSession();

  if (isLoading) {
    return <SmallLoader />;
  }

  if (!permissions.length) {
    return <>{children}</>;
  }

  const userIsAdmin = data?.user?.roles?.includes(ROLES.ADMIN);

  if (permissions.length && permissions.includes(ROLES.ADMIN) && !userIsAdmin) {
    return (
      <NoAuthDisplay description="Sorry, this page is available to Admins only." />
    );
  }

  if (permissions.length && !isLoggedIn) {
    return (
      <NoAuthDisplay description="Sorry, this page is available to Users only." />
    );
  }

  if (!userIsAdmin && userId && data?.user?._id !== userId) {
    return <NoAuthDisplay description="Sorry, this page is unavailable." />;
  }

  return <>{children}</>;
};

// export default function Example() {
//   return (
//     <>
//       {/*
//         This example requires updating your template:

//         ```
//         <html class="h-full">
//         <body class="h-full">
//         ```
//       */}
//       <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
//         <div className="text-center">
//           <p className="text-base font-semibold text-indigo-600">404</p>
//           <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
//             Page not found
//           </h1>
//           <p className="mt-6 text-base leading-7 text-gray-600">
//             Sorry, we couldn’t find the page you’re looking for.
//           </p>
//           <div className="mt-10 flex items-center justify-center gap-x-6">
//             <a
//               href="#"
//               className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//             >
//               Go back home
//             </a>
//             <a href="#" className="text-sm font-semibold text-gray-900">
//               Contact support <span aria-hidden="true">&rarr;</span>
//             </a>
//           </div>
//         </div>
//       </main>
//     </>
//   );
// }
