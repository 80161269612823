import { z } from "./xzod";

export const ROLES = {
  ADMIN: "admin",
  CREATOR: "creator",
  USER: "user",
} as const;

export type TRole = (typeof ROLES)[keyof typeof ROLES];

export const AuthLocalEntrySchema = z.object({
  password: z.string(),
});

export const AuthGoogleEntrySchema = z.object({
  googleId: z.string(),
  accessToken: z.string(),
  refreshToken: z.union([z.string(), z.undefined(), z.null()]).optional(),
});

export const AuthMagicLinkEntrySchema = z.object({
  code: z.string(),
  createdAt: z.date(),
});

export const AuthEmailEntrySchema = z.object({
  email: z.string().email(),
  local: AuthLocalEntrySchema.optional(),
  google: AuthGoogleEntrySchema.optional(),
  magiclink: AuthMagicLinkEntrySchema.optional(),
});

export const UserSchema = z.object({
  _id: z.string().min(1),
  emails: z.array(AuthEmailEntrySchema),
  lastLogin: z.date(),
  createdAt: z.date(),
  roles: z.array(z.nativeEnum(ROLES)),
  archived: z.date().nullable().optional(),
  customerId: z.string().nullable().optional(),
  displayName: z.string().optional(),
  excludeFromNotifications: z.boolean().optional(),
});
